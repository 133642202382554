import { TOMIS_CLOUD_API } from "@/constants";

/** Options passed to `tomisFetch` functions */
export interface FetchOptions {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  /** Fetch endpoint */
  endpoint: string;
  /** Body as javascript object. Will be converted to JSON in this function */
  body?: object;
  /** URL parameters as a Javascript object */
  params?: { [key: string]: string };
  /** Consumer of this function should create a signal with the following code:
    ```
    const controller = window.AbortController
      ? new AbortController()
      : undefined;

    // then pass `controller.signal` to this function
   ```
   * Use `controller.abort()` to cancel a fetch request */
  signal?: AbortSignal;
}

/** Django fetch returns this */
export interface FetchReturn<ReturnType> {
  /** Body of the response. Should match the generic Type! */
  body?: ReturnType;
  /** HTTP Status: sometimes 404 is an acceptable response! */
  status: number;
  /** Error message, usually from server.
   *
   * This may not be user-friendly, so it is safer
   * for the consumer of this function to
   * conditionally render their own error message. */
  errorMessage?: string;
  /** Did an error occur while fetching?  */
  error: boolean;
  /** Was request canceled by user? (i.e. unmounting)
   *
   * Canceled by AbortController
   */
  canceled: boolean;
}

/** Simple fetch wrapper to interact w/ TOMIS Firebase Functions API
 *
 * No need for `.catch` b/c errors are caught, then promise
 * resolved here.
 */
export async function tomisFetch<ReturnType>({
  method,
  body,
  endpoint,
  signal,
  params,
}: FetchOptions): Promise<FetchReturn<ReturnType>> {
  const urlParams = new URLSearchParams(params);
  /** Full URL to use for fetch */
  const baseUrl = TOMIS_CLOUD_API + endpoint;
  /** Entire fetch url, including query string parameters */
  const fetchUrl = params ? baseUrl + "?" + urlParams : baseUrl;

  const headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  headers.set("x-site-slug", window.tomis.chat.id);

  return fetch(fetchUrl, {
    headers: headers,
    body: body && JSON.stringify(body),
    method,
    signal,
    mode: "cors",
  })
    .then(async (res) => {
      let responseBody: ReturnType | undefined;

      const contentType = res.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        responseBody = (await res.json()) as ReturnType;
      }

      return { data: responseBody, res };
    })
    .then(async ({ data, res }) => {
      if (res.ok) {
        return {
          body: data,
          status: res.status,
          errorMessage: "",
          error: false,
          canceled: false,
        };
      }

      /** API doesn't always return errors in a reliable format  */
      const message: string =
        (data && (data as any).error) || "Unexpected error occurred";

      return {
        body: data,
        errorMessage: message,
        error: true,
        canceled: false,
        status: res.status, // consumer should check for 404 if that is expected and allowed
      };
    })
    .catch((error) => {
      /** Was request aborted in-flight? */
      const requestCanceled = Boolean(
        signal && signal.aborted && error instanceof DOMException,
      );

      if (!requestCanceled) {
        console.warn(`[${method}] ${endpoint}`, error);
      }
      return {
        canceled: requestCanceled,
        error: !requestCanceled,
        status: 500,
        body: undefined,
        errorMessage: "An unknown error occurred loading TOMIS ChatBot",
      };
    });
}
