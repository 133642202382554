import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

/** Get a timestamp for a given start time */
export const getTimestamp = (startTime?: number) => {
  if (!startTime) return null;

  const start = dayjs(startTime);
  const now = dayjs();

  switch (true) {
    case now.isSame(start, "day"):
      return "Today";
    case now.subtract(1, "day").isSame(start, "day"):
      return "Yesterday";
    default:
      return start.fromNow();
  }
};

/** Convert a string like `2024-02-08` to the locale date string.
 *
 * This is useful for changing the value of `<input type="date">` to a more human-readable format.
 *
 * Learn more: <https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value>
 */
export function dateStringToLocaleString(dateString: string) {
  const [year, month, date] = dateString.split("-").map(Number);

  // Months are zero-indexed
  return new Date(year, month - 1, date).toLocaleDateString();
}

/** Convert a string like `2024-02-08` to a `Date` object.
 *
 * This is useful for changing the value of `<input type="date">` to a `Date` object.
 */
export function dateStringToDate(dateString: string) {
  const [year, month, date] = dateString.split("-").map(Number);

  // Months are zero-indexed
  return new Date(year, month - 1, date);
}

/** Get the current date in the locale date string format (e.g. `2024-02-08`). */
export function getLocaleDateString(date: Date = new Date()) {
  const today = date;
  const year = today.getFullYear();
  // getMonth() returns 0-11; adding 1 to get 1-12 for months
  const month = ("0" + (today.getMonth() + 1)).slice(-2); // Ensures two digits
  const day = ("0" + today.getDate()).slice(-2); // Ensures two digits

  return `${year}-${month}-${day}`;
}

/** Add days to a Javascript date object */
export function addDays(originalDate: Date, days: number) {
  const date = new Date(originalDate); // Clone the original date to avoid modifying it
  date.setDate(date.getDate() + days); // Add days
  return date;
}

/** Get User Locale based on `window.navigator.language`, defaulting to English  */
export const getUserLocale = () => {
  return (
    navigator.language ||
    (navigator.languages && navigator.languages[0]) ||
    "en-US"
  );
};

/** Format date based on user locale. 
 * 
 * Example usage: 
 * 
 * ```ts
 * toLocaleDateString({
    date: new Date(transcript.updatedAt),
    options: {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      timeZoneName: "short",
    },
  }) // "Jan 1, 2022, 12:00 AM PST"
 * ```
  */
export function toLocaleDateString({
  date,
  options,
  locale,
}: {
  date: Date;
  options?: Intl.DateTimeFormatOptions;
  /** If not provided, locale is determined based on `navigator.language` automatically */
  locale?: string;
}) {
  const userLocale = locale || getUserLocale();

  return date.toLocaleString(userLocale, options);
}
