import { styled } from "styled-components";

export const Button = styled.div<{ disabled: boolean }>(
  ({ disabled, theme }) => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.radii.round,
      color: disabled ? theme.colors.grayMed : theme.colors.grayDark,
      border: `1px solid ${
        disabled ? theme.colors.grayMed : theme.colors.grayDark
      }`,
      background: "none",
      transition: "background-color 0.25s",
      padding: "8px",
      margin: "1px",

      "&:hover": {
        //backgroundColor: disabled ? "none" : theme.colors.grayMed,
        borderColor: disabled ? theme.colors.grayMed : theme.colors.grayDark,
        color: disabled ? theme.colors.grayMed : theme.colors.grayDark,
        borderWidth: "2px",
        margin: 0,
        cursor: "pointer",
      },

      "&:focus": {
        outline: 0,
      },
    };
  },
);

export const Container = styled.div(() => {
  return {
    display: "flex",
    alignItems: "baseline",
  };
});

export const ValueContainer = styled.div(() => {
  return {
    padding: "0 16px",
  };
});
