import { BaseRequest, BaseVersion } from "@voiceflow/base-types";
import type {
  AuthVerify,
  PublicVerify,
  RuntimeAction,
  RuntimeOptions as SDKRuntimeOptions,
} from "@voiceflow/sdk-runtime";
import type {
  SystemResponseProps,
  UserResponseProps,
} from "@tomis-tech/chat-ui";

export * from "@/types";

export enum ChatPersistence {
  MEMORY = "memory",
  LOCAL_STORAGE = "localStorage",
  SESSION_STORAGE = "sessionStorage",
}
export enum ChatPosition {
  LEFT = "left",
  RIGHT = "right",
}

export interface ChatPublishing extends BaseVersion.Publishing {
  title?: string;
  image?: string;
  color?: string;
  avatar?: string;
  spacing?: {
    side?: number;
    bottom?: number;
  };
  launcher?: string;
  position?: ChatPosition;
  watermark?: boolean;
  feedback?: boolean;
  stylesheet?: string | string[];
  persistence?: ChatPersistence;
  description?: string;
}

export enum TurnType {
  USER = "user",
  SYSTEM = "system",
}

export interface UserTurnProps extends UserResponseProps {
  id: string;
  type: TurnType.USER;
}

export interface SystemTurnProps extends Omit<SystemResponseProps, "avatar"> {
  id: string;
  type: TurnType.SYSTEM;
  timestamp: number;
}

export type TurnProps = UserTurnProps | SystemTurnProps;

export type { RuntimeAction };

export type SendMessage = (
  action: RuntimeAction,
  message?: string,
) => Promise<void>;

export interface LaunchOptions {
  event?: BaseRequest.BaseRequest;
}

export interface RuntimeOptions<
  Verify extends AuthVerify | PublicVerify = AuthVerify | PublicVerify,
> extends SDKRuntimeOptions<Verify> {
  user?:
    | {
        name?: string;
        image?: string;
      }
    | undefined;
  userID?: string;
  versionID?: string | undefined;
  launch?: LaunchOptions;

  allowDangerousHTML?: boolean;
}

export enum SessionStatus {
  IDLE = "IDLE",
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
}

export interface SessionOptions {
  userID: string;
  turns?: TurnProps[];
  startTime?: number;
  status?: SessionStatus;
}

export type Assistant = Omit<
  ChatPublishing & Required<Omit<ChatPublishing, "launcher" | "stylesheet">>,
  "selectedIntents"
>;

export interface ChatConfig extends RuntimeOptions<PublicVerify> {
  assistant?: Assistant;
}

/** Create a type that accept enum values and strings from an enum 
 * 
 * The TypeScript type StringifiedEnum<T extends string> = T | ${T}; is an advanced type that represents a union of a string literal type T and its template literal string equivalent. Let's break it down:

Generic Type T extends string:

T is a generic type parameter.
extends string means that T is constrained to be a subtype of string. Essentially, T can be any string literal type.
Union Type T | ${T} :

This is a union type, meaning the type can be either T or ${T}.
T refers to the original string literal type.
`${T}` is a template literal type. It creates a new string literal type by interpolating T. In this case, it's effectively the same as T but emphasizes that it's a string.
So, StringifiedEnum<T> can be used in a situation where you want to accept either a string literal type T or its stringified (template literal) form. For example, if T is a specific set of string literals representing an enum, StringifiedEnum<T> would accept both the enum members and their stringified counterparts.

Here's a simple example:

```typescript
type Color = "red" | "green" | "blue";
type StringifiedColor = StringifiedEnum<Color>;

let color: StringifiedColor;

color = "red";    // Valid
color = "green";  // Valid
color = `blue`;   // Also valid
```

In this example, StringifiedColor can be any of the values "red", "green", "blue", or their template literal forms (which, in this case, are the same). This type is particularly useful in TypeScript for representing types that can be both a literal and its string form, often seen in scenarios involving enums or configuration objects.
 */
export type StringifiedEnum<T extends string> = T | `${T}`;
