import { useState, useEffect } from "react";
import debounce from "debounce";

/** Determine if window innerWidth is less than 768.
 *
 * Is debounced to prevent unnecessary re-renders,
 * will update state 100ms after window resize event.
 */
export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= 768,
  );

  useEffect(() => {
    // Create a debounced function
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 768);
    }, 100);

    window.addEventListener("resize", handleResize);

    // Set initial state
    handleResize();

    // Cleanup function
    return () => {
      handleResize.clear();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};
