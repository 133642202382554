import React, { createContext, useMemo } from "react";

import { RuntimeState, Settings, useRuntimeState } from "./useRuntimeState";

// split up API and state to prevent unnecessary re-renders
export const RuntimeStateAPIContext = createContext<RuntimeState["api"]>(
  {} as any,
);
export const RuntimeStateContext = createContext<RuntimeState["state"]>(
  {} as any,
);

export const RuntimeProvider: React.FC<React.PropsWithChildren<Settings>> = ({
  children,
  assistant,
  config,
  traceHandlers = [],
  feedback,
}) => {
  const store = useRuntimeState({ assistant, config, traceHandlers, feedback });

  // api is a static object, so we can use useMemo to prevent unnecessary re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const api = useMemo(() => store.api, []);

  return (
    <RuntimeStateAPIContext.Provider value={api}>
      <RuntimeStateContext.Provider value={store.state}>
        {children}
      </RuntimeStateContext.Provider>
    </RuntimeStateAPIContext.Provider>
  );
};
