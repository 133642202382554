import { Icon } from "@tomis-tech/chat-ui";

import { UseQuantityReturn } from "./useQuantity";
import { Button, Container, ValueContainer } from "./styled";

interface QuantityProps extends UseQuantityReturn {}

/** Show availability selector in a selector that slides from the bottom  */
export const Quantity: React.FC<QuantityProps> = ({
  value,
  increment,
  decrement,
  min,
  max,
}) => {
  return (
    <Container>
      <Button disabled={min === value} onClick={decrement}>
        <Icon name="minus" size={16} />
      </Button>
      <ValueContainer>{value}</ValueContainer>
      <Button disabled={max === value} onClick={increment}>
        <Icon name="plus" size={16} />
      </Button>
    </Container>
  );
};
