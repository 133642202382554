import { useState } from "react";
import { ProductListing, CheckAvailabilityProducts } from "@tomis-tech/types";

export interface UseProductsProps extends CheckAvailabilityProducts {}

/** `ProductListing` with `isSelected` boolean */
export interface ProductSelection extends ProductListing {
  /** `true` if product has been selected, else `false` */
  isSelected: boolean;
}

/** Values returned from `useProducts` hook */
export interface UseProductsReturn {
  /** Filtered list of products to display */
  filtered: ProductSelection[];
  /** All products (probably won't be displayed) */
  all: ProductSelection[];
  /** All products that have been selected to check availability */
  selected: ProductSelection[];
  /** All tags compiled from "all" products array */
  tags: string[];
  /** Current tag filter. Will default to "All" */
  tagFilter: string;
  /** `onChange` handler for a `<select>` element */
  handleTagFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /** `onClick` handler for clicking on a product. Handles select and unselect based on current state */
  handleProductSelection: (product: ProductSelection) => void;
}

/** Product selector */
export const useProducts = ({
  all,
  defaultTag,
}: UseProductsProps): UseProductsReturn => {
  const tags = [...new Set(all.flatMap((p) => p.tags))];

  const [tagFilter, setTagFilter] = useState(
    defaultTag && tags.includes(defaultTag) ? defaultTag : "All",
  );
  const [selectedProducts, setSelectedProducts] = useState<ProductListing[]>(
    [],
  );

  /** Handle tag filter change.
   *
   * Will also clear all selections when the tag filter changes. */
  function handleTagFilterChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newTag = e.target.value;
    if (newTag !== tagFilter) {
      setSelectedProducts([]);
    }

    setTagFilter(newTag);
  }

  function handleProductSelection(product: ProductSelection) {
    if (product.isSelected) {
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  }

  const allProducts: ProductSelection[] = all.map((p) => ({
    ...p,
    isSelected: selectedProducts.some((sp) => sp.id === p.id),
  }));

  const filteredProducts: ProductSelection[] = allProducts.filter(
    (p) => tagFilter === "All" || p.tags.includes(tagFilter),
  );

  return {
    all: allProducts,
    filtered: filteredProducts,
    selected: selectedProducts.map((p) => ({ ...p, isSelected: true })),
    tags: tags,
    tagFilter,
    handleTagFilterChange,
    handleProductSelection,
  };
};
