import { StringifiedEnum } from "@/common/types";
import { TomisEvent } from "./types";

/** Setup `window.gtag` and `window.dataLayer` if they don't already exist */
export function setupGoogleAnalytics4() {
  window.dataLayer = window.dataLayer || [];
  /** Create `gtag` command if client website is using GTM */
  window.gtag =
    window.gtag ||
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    };
}

/** Send event to all "gtag" on the site. 
 * 
 * This works the same way as the previous version of the TOMIS ChatBot.
 *  Calling `window.gtag` automatically pushes to dataLayer.
  * 
  * Example of GTM dataLayer after the gtag event: 
  * 
  dataLayer.push({
    event: "chatbot_window_opened",
    eventModel: {
      release: "0.0.1",
      site: "site-slug",
    },
    gtm.uniqueEventId: 6
  })
  * 
  * Therefore, event parameters can be converted to GTM variables by 
  * accessing `eventModel[paramName]`
  */
export function trackGA4Event(
  eventName: StringifiedEnum<TomisEvent>,
  eventParams: Record<string, any> = {},
) {
  let googleAnalyticsIds = window.tomis.chat.config.googleAnalyticsIds;

  if (!googleAnalyticsIds || googleAnalyticsIds.length === 0) {
    googleAnalyticsIds = getGA4MeasurementIds();
  }

  eventParams.send_to = googleAnalyticsIds;

  // prepend "chatbot_" to event name for clarity in customer GA4 dashboards
  window.gtag("event", `chatbot_${eventName}`, eventParams);
}

/** Attempt to get all Google Analytics 4 Measurement IDs from the parent window.
 *
 * Uses a few tricks that Austin learned to get Google Analytics 4 IDs
 * from a webpage, regardless of how GA4 is installed.
 *
 * 1. Look for gtag scripts in the DOM
 * 2. Look for `_ga_` cookies
 *
 * @returns array of Google Analytics 4 Measurement IDs. Will return empty array if none.
 */
export function getGA4MeasurementIds(): string[] {
  try {
    /** Google Analytics 4 Measurement IDs */
    let measurementIds: string[] = [];

    /** Google Analytics 4 includes this script for each measurement id.
     * Even if loaded via GTM, GTM will add this script to the DOM */
    document
      .querySelectorAll(
        "script[src^='https://www.googletagmanager.com/gtag/js?id=G-']",
      )
      .forEach(function getGA4Information(script) {
        const measurementId = new URL(
          (script as HTMLScriptElement).src,
        ).searchParams.get("id")!;
        measurementIds.push(measurementId);
      });

    if (measurementIds.length === 0) {
      // If gtag scripts aren't loaded on the site for some reason, try to get
      // GA4 Measurement IDs from cookies
      const gaCookies = document.cookie
        .split("; ")
        .filter(function getGA4(pair) {
          return pair.split("=", 1)[0].indexOf("_ga_") > -1;
        });

      if (gaCookies.length > 0) {
        const ga4MeasurementIds = gaCookies.map(
          (c) => "G-" + c.split("_ga_")[1].split("=")[0],
        );
        measurementIds = ga4MeasurementIds;
      } else {
        console.warn(
          "[TOMIS Chatbot] Google Analytics 4 is not found on this website",
        );
      }
    }
    return measurementIds;
  } catch (error) {
    console.warn(
      "[TOMIS Chatbot] Error occurred when trying to search for GA4 on the site",
    );
    return [];
  }
}
