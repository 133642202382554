import { useContext, useState } from "react";
import { Button, Icon, useTheme } from "@tomis-tech/chat-ui";

import {
  getProductAvailabilityAndDetails,
  addCheckAvailabilitySearchTagToTranscript,
} from "@/services/checkAvailability";
import { UseCheckAvailabilityReturn } from "@/hooks";
import { RuntimeStateAPIContext } from "@/contexts";
import {
  AvailabilityText,
  Container,
  Header,
  ScrollContainer,
  Step,
  StepContent,
  StepList,
  StepTitle,
  HeaderButton,
  StepDescription,
} from "./styled";
import { Dates, useDates } from "./Dates";
import { Quantity, useQuantity } from "./Quantity";
import { Products, useProducts } from "./Products";
import {
  addCheckAvailabilityErrorTurn,
  addCheckAvailabilityRequestTurn,
  addCheckAvailabilitySuccessTurn,
} from "./services";

/** Show availability selector in a selector that slides from the bottom  */
const CheckAvailability: React.FC<UseCheckAvailabilityReturn> = ({
  isVisible,
  hide,
  config,
  ready,
}) => {
  const quantity = useQuantity(config.quantity);
  const dates = useDates({ ...config.date, products: config.products.all });
  const products = useProducts(config.products);

  const runtime = useContext(RuntimeStateAPIContext);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();

  /** Search button is disabled if all fields are not completed */
  const canSearch =
    !dates.start.errorMessage &&
    !dates.end.errorMessage &&
    products.selected.length > 0 &&
    !isSearching;

  async function handleSearch() {
    if (isSearching || !canSearch) return;

    setIsSearching(true);

    const dateEnd = dates.isRange ? dates.end.value : dates.start.value;

    await addCheckAvailabilityRequestTurn({
      runtime,
      config,
      products: products.selected,
      quantity: quantity.value,
      dateStart: dates.start.value,
      dateEnd,
    });

    // Add check_availability_search tag to transcript, purely for analytics purposes
    // Don't use await here, we don't need to wait for this to complete
    addCheckAvailabilitySearchTagToTranscript();

    const availability = await getProductAvailabilityAndDetails({
      dateEnd,
      dateStart: dates.start.value,
      products: products.selected,
      quantity: quantity.value,
    });

    setIsSearching(false);
    hide();

    if (availability.error) {
      // Server error or fetch error occurred when searching for availability
      addCheckAvailabilityErrorTurn({ runtime });
    } else {
      addCheckAvailabilitySuccessTurn({
        availability: availability.data,
        products: availability.products,
        runtime,
      });
    }
  }

  // No check availability message has been received yet
  if (!ready) return null;

  return (
    <Container $visible={isVisible} id="tomis-check-availability">
      <Header>
        <AvailabilityText>Availability</AvailabilityText>
        <HeaderButton onClick={hide}>
          <Icon color={theme.colors.black} name="close" size={16} />
        </HeaderButton>
      </Header>
      <ScrollContainer>
        <StepList>
          <Step $completed={true}>
            <StepContent>
              <StepTitle>{config.date.alias}</StepTitle>
              <StepDescription>{config.date.description}</StepDescription>
              {/** Date selector. Single date or date range. */}
              <Dates {...dates} max={config.date.max} />
            </StepContent>
          </Step>
          <Step $completed={true}>
            <StepContent>
              <StepTitle>{config.quantity.alias}</StepTitle>
              <StepDescription>{config.quantity.description}</StepDescription>
              {/** Quantity selector */}
              <Quantity {...quantity} />
            </StepContent>
          </Step>
          <Step $completed={true}>
            <StepContent>
              <StepTitle>{config.products.alias}</StepTitle>
              <StepDescription>{config.products.description}</StepDescription>
              {/** Product selector */}
              <Products {...products} />
            </StepContent>
          </Step>
        </StepList>
        <Button
          variant="info"
          style={{
            width: "100%",
            backgroundColor: !canSearch ? theme.colors.grayMed : undefined,
          }}
          disabled={!canSearch}
          onClick={handleSearch}
        >
          {isSearching ? "Searching..." : "Search"}
        </Button>
      </ScrollContainer>
    </Container>
  );
};

/** Show Check Availability widget in a selector that slides from the bottom  */
export const CheckAvailabilityWrapper: React.FC<UseCheckAvailabilityReturn> = (
  availability,
) => {
  // No check availability message has been received yet
  if (!availability.ready) return null;

  return <CheckAvailability {...availability} />;
};
