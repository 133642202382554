import { styled, CSSProperties } from "styled-components";

const CHAT_WIDTH = 380;
const MAX_CHAT_HEIGHT = 800;

export const ChatContainer = styled.div(({ theme }) => {
  return {
    width: CHAT_WIDTH,
    overflow: "hidden",
    borderRadius: theme.radii[2],
    boxShadow: `0 2px 48px rgba(19,33,68,0.16), 0 0 0 1px ${theme.shadows[4]}`,
    height: "90%",
    maxHeight: MAX_CHAT_HEIGHT,

    // mobile device styles
    "@media (max-width: 768px)": {
      position: "fixed",
      height: "unset",
      maxHeight: "unset",
      width: "unset",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      borderRadius: 0,
      boxShadow: "none",
    },
  };
});

export const LauncherContainer = styled.div({});

const animateInStyles: CSSProperties = {
  opacity: 1,
  pointerEvents: "auto",
  transform: "translateY(0%)",
  transition:
    "transform 300ms cubic-bezier(0, 0.95, 0.1, 1), opacity 150ms linear",
};

const animateOutStyles: CSSProperties = {
  opacity: 0,
  pointerEvents: "none",
  transform: "translateY(100%)",
  transition:
    "transform 300ms cubic-bezier(0.85, 0, 0.6, 1), opacity 150ms linear",
};

interface ContainerProps {
  /** Is chat window currently showing dialog */
  $withChat: boolean;
  /** If true, chat widget is completely hidden from user */
  $isHidden: boolean;
}

export const Container = styled.div<ContainerProps>(
  ({ $withChat, $isHidden }) => {
    return {
      position: "fixed",
      inset: 0,
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      pointerEvents: "none",
      zIndex: 100000000,
      display: $isHidden ? "none" : undefined,

      [`
    & > ${LauncherContainer},
    & > ${ChatContainer}
  `]: {
        position: "absolute",
      },

      [`& > ${ChatContainer}`]: {
        ...($withChat ? animateInStyles : animateOutStyles),
      },

      [`& > ${LauncherContainer}`]: {
        ...($withChat ? animateOutStyles : animateInStyles),
      },
    };
  },
);
