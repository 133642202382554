import { Button, useTheme } from "@tomis-tech/chat-ui";

import { UseDatesReturn } from "./useDates";
import { Input, GridContainer, GridItem, ErrorMessage } from "./styled";

interface DatesProps extends UseDatesReturn {
  /** Maximum allowed date for `<input type="date" />. Formatted like `YYYY-MM-DD`  */
  max?: string;
}

/** Show Date selector(s), including labels, inputs, error messages, and button
 * to show date range. */
export const Dates: React.FC<DatesProps> = (dates) => {
  const endDateMaximum = dates.max;

  const theme = useTheme();

  return (
    <GridContainer>
      <GridItem $p={dates.isRange ? "0 12px 0 0" : "0"}>
        {dates.isRange ? "Start" : ""}
      </GridItem>
      <GridItem>
        <Input
          required
          min={dates.startMin}
          max={endDateMaximum}
          value={dates.start.value}
          $valid={!dates.start.errorMessage}
          type="date"
          onChange={dates.handleStartChange}
        />
      </GridItem>
      <GridItem>{/** Intentionally left blank */}</GridItem>
      <GridItem>
        <ErrorMessage $absolute={!dates.isRange}>
          {dates.start.errorMessage}
        </ErrorMessage>
      </GridItem>
      <GridItem $p={dates.isRange ? "0 12px 0 0" : "0"}>
        {dates.isRange ? "End" : ""}
      </GridItem>
      <GridItem>
        {dates.isRange ? (
          <Input
            required
            min={dates.start.value}
            max={endDateMaximum}
            value={dates.end.value}
            $valid={!dates.end.errorMessage}
            type="date"
            onChange={dates.handleEndChange}
          />
        ) : (
          <Button
            variant="outline"
            onClick={dates.toggleIsRange}
            style={{
              height: "28px",
              color: theme.colors.grayDark,
              borderColor: theme.colors.grayMed,
              marginTop: "4px",
              fontWeight: "normal",
            }}
          >
            search by date range
          </Button>
        )}
      </GridItem>
      <GridItem>{/** Intentionally left blank */}</GridItem>
      <GridItem>
        <ErrorMessage>{dates.end.errorMessage}</ErrorMessage>
      </GridItem>
      <GridItem></GridItem>
    </GridContainer>
  );
};
