import {
  CardV2TraceComponent,
  ChoiceTraceComponent,
  RuntimeAction,
  TextTraceComponent,
  Trace,
  TraceDeclaration,
  VisualTraceComponent,
} from "@voiceflow/sdk-runtime";
import * as DTOs from "@voiceflow/dtos";
import {
  CardProps,
  SystemResponseProps,
  MessageType,
} from "@tomis-tech/chat-ui";

export interface RuntimeMessage
  extends Pick<SystemResponseProps, "messages" | "actions"> {}

const isValidCard = (card: CardProps) => {
  return (
    !!card.title ||
    !!card.description ||
    !!card.image ||
    !!card.actions?.filter(({ name }) => !!name).length
  );
};

export const MESSAGE_TRACES: TraceDeclaration<RuntimeMessage, any>[] = [
  TextTraceComponent(({ context }, trace) => {
    if (!DTOs.TextTraceDTO.safeParse(trace).success) return context;

    const { slate, message, ai, delay } = trace.payload;

    context.messages.push({
      type: MessageType.TEXT,
      text: slate?.content || message,
      delay,
      ...(ai ? { ai } : {}),
    });

    return context;
  }),
  VisualTraceComponent(({ context }, trace) => {
    if (!DTOs.VisualTraceDTO.safeParse(trace).success) return context;

    context.messages.push({
      type: MessageType.IMAGE,
      url: trace.payload.image,
    });
    return context;
  }),
  ChoiceTraceComponent(({ context }, trace) => {
    if (!DTOs.ChoiceTraceDTO.safeParse(trace).success) return context;

    const {
      payload: { buttons },
    } = trace;
    context.actions = (
      buttons as { name: string; request: RuntimeAction }[]
    ).map(({ name, request }) => ({
      name,
      request,
    }));
    return context;
  }),
  CardV2TraceComponent(({ context }, trace) => {
    if (!DTOs.CardTraceDTO.safeParse(trace).success) return context;

    const {
      payload: { title, imageUrl, description, buttons },
    } = trace;
    const card: CardProps = {
      title,
      description: description.text,
      image: imageUrl,
      // Don't show buttons without a name
      actions: buttons
        .filter((a) => Boolean(a.name))
        .map(({ name, request }) => ({ name, request })),
    };

    if (isValidCard(card)) {
      context.messages.push({
        type: "card",
        ...card,
      });
    }
    return context;
  }),
  {
    canHandle: ({ type }) => type === Trace.TraceType.CAROUSEL,
    handle: ({ context }, trace: Trace.Carousel) => {
      if (!DTOs.CarouselTraceDTO.safeParse(trace).success) return context;

      const cards: CardProps[] = trace.payload.cards
        .map(({ title, description, imageUrl, buttons }) => ({
          title,
          description: description.text,
          image: imageUrl,
          actions: buttons.map(({ name, request }) => {
            const isUrl = request.payload.actions?.[0]?.type === "open_url";

            return {
              name,
              request: isUrl ? undefined : request,
              url: isUrl
                ? (request.payload.actions?.[0]?.payload as { url: string })
                    ?.url
                : undefined,
            };
          }),
        }))
        .filter(isValidCard);

      if (cards.length) {
        context.messages.push({
          type: MessageType.CAROUSEL,
          cards,
        });
      }

      return context;
    },
  },
  {
    /** Custom handler for Check Availability carousels. This is necessary b/c Voiceflow doesn't
     * allow us to add extra fields to the payload of a Carousel trace via Voiceflow functions.
     *
     * Therefore we created our own type of message that has a different payload, but is mapped
     * to the same chat-ui component (<Carousel />).
     */
    canHandle: ({ type }) => type === MessageType.CHECK_AVAILABILITY_CAROUSEL,
    handle: ({ context }, trace) => {
      const cards: CardProps[] = trace.payload.cards
        .map(({ title, description, image, buttons }: any) => ({
          title,
          description,
          image,
          actions: buttons.map(({ name, url }: any) => {
            return {
              name,
              url,
              type: "check_availability_link",
              onClick: () => {},
            };
          }),
        }))
        .filter(isValidCard);

      if (cards.length) {
        context.messages.push({
          type: MessageType.CHECK_AVAILABILITY_CAROUSEL,
          cards,
        });
      }

      return context;
    },
  },
  {
    canHandle: ({ type }) => type === Trace.TraceType.END,
    handle: ({ context }) => {
      context.messages.push({ type: MessageType.END });
      return context;
    },
  },
  // custom messages
  {
    canHandle: ({ type }) => type === MessageType.CHECK_AVAILABILITY,
    handle: ({ context }, trace) => {
      context.messages.push({
        ...trace,
        type: MessageType.CHECK_AVAILABILITY,
        payload: trace.payload,
        // To be implemented in a later step
        onClick: () => {},
      });
      return context;
    },
  },
  {
    canHandle: ({ type }) => type === MessageType.CHECK_AVAILABILITY_AGAIN,
    handle: ({ context }) => {
      context.messages.push({
        type: MessageType.CHECK_AVAILABILITY_AGAIN,
        // To be implemented in a later step
        onClick: () => {},
      });
      return context;
    },
  },
];
