import { useMemo } from "react";
import {
  PublicVerify,
  RuntimeAction,
  VoiceflowRuntime,
} from "@voiceflow/sdk-runtime";
// @ts-ignore Voiceflow doesn't have types for this
import { serializeToText } from "@voiceflow/slate-serializer/text";
import { Model } from "@voiceflow/base-types/build/cjs/models/transcripts";
import Bowser from "bowser";
import { TranscriptTag } from "@tomis-tech/types";

import { RuntimeOptions, SessionOptions, ChatPersistence } from "@/common";
import { saveTranscriptToStorage } from "@/utils/session";
import { IS_TEST_CONVERSATION } from "@/constants";
import { addTagToTranscript } from "@/services";
import { MESSAGE_TRACES, RuntimeMessage } from "./messages";

export enum FeedbackName {
  POSITIVE = "Thumbs up",
  NEGATIVE = "Thumbs down",
}

export const createContext = () => ({
  messages: [],
});

export const useRuntimeAPI = ({
  url,
  user,
  userID,
  verify,
  versionID,
  traceHandlers = [],
}: RuntimeOptions<PublicVerify> &
  Pick<SessionOptions, "userID"> & {
    traceHandlers?: typeof MESSAGE_TRACES;
  }): {
  interact: (action: RuntimeAction) => Promise<RuntimeMessage>;
  saveTranscript: () => Promise<Model>;
} => {
  const runtime: VoiceflowRuntime<RuntimeMessage> = useMemo(
    () =>
      new VoiceflowRuntime<RuntimeMessage>({
        verify,
        url,
        traces: [...MESSAGE_TRACES, ...traceHandlers],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const interact = async (action: RuntimeAction) =>
    runtime.interact(createContext(), {
      sessionID: encodeURIComponent(userID),
      action,
      ...(versionID && { versionID }),
    });

  /** 1. Create transcript using voiceflow runtime SDK
   * 2. If testing conversation, add "Testing" tag to transcript
   * 3. Save transcript to local storage
   */
  const saveTranscript = async () => {
    const {
      browser: { name: browser },
      os: { name: os },
      platform: { type: device },
    } = Bowser.parse(window.navigator.userAgent);

    const transcript = await runtime.createTranscript(userID, {
      ...(os && { os }),
      ...(browser && { browser }),
      ...(device && { device }),
      ...(user && { user }),
    });

    if (IS_TEST_CONVERSATION) {
      await addTagToTranscript({
        userId: userID,
        tags: [TranscriptTag.Testing],
      });
    }

    saveTranscriptToStorage(
      ChatPersistence.LOCAL_STORAGE,
      verify.projectID,
      transcript,
    );

    return transcript;
  };

  return { interact, saveTranscript };
};
