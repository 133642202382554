import { styled } from "styled-components";
import { Button as _Button } from "@tomis-tech/chat-ui";

export const SelectTag = styled.select(({ theme }) => ({
  padding: `${theme.space[1]} ${theme.space[3]}`,
  borderRadius: theme.radii[1],
  border: `1px solid ${theme.colors.grayDark}`,
  margin: "1px",
  fontSize: "16px",
  color: theme.colors.grayDark,
  "-webkit-appearance:": "none",
  appearance: "none",
  background: "none",
  width: "150px",

  "&:hover, &:focus": {
    borderColor: theme.colors.grayDark,
    borderWidth: "2px",
    margin: 0,
  },
  "&:focus-visible": {
    outline: "none",
  },
}));

export const ContainerProducts = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-start",

    overflow: "auto",
    padding: `${theme.space[3]} ${theme.space[4]} ${theme.space[3]} 0`,
  };
});

export const ItemProduct = styled.div(() => {
  return {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    margin: "8px 0",
    alignItems: "center",
  };
});
