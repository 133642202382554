import { styled } from "styled-components";
import { Button as _Button } from "@tomis-tech/chat-ui";

type ContainerProps = {
  /** Is the Availability selector currently visible? */
  $visible: boolean;
};

export const Container = styled.div<ContainerProps>(({ theme, $visible }) => {
  return {
    position: "absolute",
    overflow: "hidden",
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
    maxHeight: "100%",
    boxShadow: "0 16px 120px 16px rgba(0, 0, 0, 1)",
    backgroundColor: theme.colors.white,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    opacity: $visible ? 1 : 0,
    visibility: $visible ? "visible" : "hidden",
    transform: $visible ? "translateY(0%)" : "translateY(100%)", // Slide up from bottom
    transition: $visible
      ? "transform 300ms ease-out, opacity 300ms"
      : "transform 300ms ease-out, opacity 300ms, visibility 300ms",
    pointerEvents: $visible ? "auto" : "none",
  };
});

export const Header = styled.div(({ theme }) => {
  return {
    display: "flex",
    backgroundColor: theme.colors.grayLight,
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.space[4]} ${theme.space[5]}`,
  };
});

export const HeaderButton = styled.button(({ theme }) => {
  return {
    border: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 32,
    width: 32,
    marginLeft: 8,
    borderRadius: 6,
    background: "none",
    transition: "background-color 0.25s",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colors.white,
    },

    "&:focus": {
      outline: 0,
    },
  };
});

export const AvailabilityText = styled.div(() => {
  return {
    margin: 0,
    fontSize: "24px",
    fontWeight: "bold",
  };
});

export const ScrollContainer = styled.div(() => {
  return {
    overflowY: "auto",
    padding: "32px 16px",
  };
});

// Styled component for the container with function-based syntax
export const StepList = styled.ul(() => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
  overflow: "hidden",
}));

// Styled component for each step
export const Step = styled.li<{ $completed: boolean }>(
  ({ $completed, theme }) => ({
    position: "relative",
    paddingLeft: "36px",
    paddingBottom: theme.space[6],
    "&::before": {
      content: '""',
      position: "absolute",
      left: "0",
      top: "0",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: $completed ? theme.colors.primary : theme.colors.grayMed,
      zIndex: "1",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: "10px",
      top: "20px",
      width: "2px",
      height: "100%",
      backgroundColor: $completed ? theme.colors.primary : theme.colors.grayMed,
      zIndex: "0",
    },
    "&:last-child::after": {
      display: "none",
    },
  }),
);

export const StepContent = styled.div(() => ({
  position: "relative",
}));

export const StepTitle = styled.div(() => ({
  fontSize: "20px",
  fontWeight: 600,
}));

export const StepDescription = styled.div(({ theme }) => ({
  marginBottom: theme.space[3],
  color: theme.colors.grayDark,
}));
