import { useState } from "react";
import { CheckAvailabilityQuantity } from "@tomis-tech/types";

export interface UseDatesProps extends CheckAvailabilityQuantity {}

/** Values returned from `useQuantity` hook */
export interface UseQuantityReturn {
  value: number;
  /** Will not increment if `max` has been reached */
  increment: () => void;
  /** Will not decrement if `min` has been reached */
  decrement: () => void;
  /** Minimum allowed value */
  min: number;
  /** Maximum allowed value */
  max: number;
}

/** Quantity selector logic and state  */
export const useQuantity = ({
  default: defaultValue = 1, // `default` is a reserved word
  min = 1,
  max = 99,
  ...props
}: UseDatesProps): UseQuantityReturn => {
  const [quantity, setQuantity] = useState(defaultValue);

  function handleQuantityIncrement() {
    if (quantity < max) {
      setQuantity(quantity + 1);
    }
  }

  function handleQuantityDecrement() {
    if (quantity > min) {
      setQuantity(quantity - 1);
    }
  }

  return {
    value: quantity,
    increment: handleQuantityIncrement,
    decrement: handleQuantityDecrement,
    min,
    max,
    ...props,
  };
};
