import * as Sentry from "@sentry/react";

import { TOMIS_CLOUD_API, VF_API_URL } from "@/constants";
import pkg from "../../package.json";

/* Initialize Sentry as early as possible in your application's lifecycle. */
export function initSentry() {
  const currentSentryClient = Sentry.getClient();

  if (currentSentryClient) {
    // Sentry has already been initialized, don't try again
    return;
  }

  Sentry.init({
    dsn: "https://68e36f1f8ee4e62166e5b305ebaf3630@o141882.ingest.sentry.io/4506668622217216",
    environment: import.meta.env.MODE, // This is the environment that the application is running in
    release: pkg.version, // This is the version of the application
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          TOMIS_CLOUD_API,
          VF_API_URL,
          // "https://app.tomis.tech/api",
        ],
      }),
      Sentry.replayIntegration({
        // Set 'maskAllText' to true to mask all viewable text nodes
        maskAllText: false,
        // Set 'blockAllMedia' to true to block all media elements
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function setSentryTags(tags: Record<string, string>) {
  Sentry.setTags(tags);
}
