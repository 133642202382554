import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { useTheme } from "@tomis-tech/chat-ui";

import { ChatWidget, ChatWidgetProps } from "@/views/ChatWidget";
import { VF_API_URL } from "@/constants";
import { ChatPersistence, ChatPosition, RuntimeOptions } from "@/common";
import { RuntimeProvider } from "@/contexts";
import { ErrorBoundary } from "./ErrorBoundary";
import { ChatUser } from "./types";

interface AppProps extends ChatWidgetProps {
  user: ChatUser;
}

/** React App parent component */
export const App: React.FC<AppProps> = ({
  config,
  onReady,
  user,
  embed = false,
  feedback = "disabled",
}) => {
  const voiceflowUser: Pick<RuntimeOptions, "user" | "userID"> = {
    user: {
      image: user.image,
      name: user.name,
    },
    userID: user.id,
  };

  return (
    <ErrorBoundary fallback={null}>
      <RuntimeProvider
        feedback={feedback}
        assistant={{
          avatar: config.image,
          color: config.color,
          description: config.description,
          feedback: false,
          image: config.image,
          persistence: ChatPersistence.LOCAL_STORAGE,
          position:
            config.position === "left" ? ChatPosition.LEFT : ChatPosition.RIGHT,
          title: config.name,
          watermark: config.watermark,
          spacing: config.spacing,
          launcher: config.launcher,
        }}
        config={{
          url: VF_API_URL,
          verify: { projectID: config.projectId },
          allowDangerousHTML: false,
          versionID: config.versionId, // production or development
          ...voiceflowUser,
          launch: {
            event: {
              type: "launch",
              payload: {
                page: window.location.href,
                user_name: user.name || undefined,
                // user_id is automatically set by the runtime if included
              },
            },
          },
        }}
      >
        <StyledComponentsThemeProviderLocal>
          <ChatWidget
            onReady={onReady}
            config={config}
            embed={embed}
            feedback={feedback}
            user={user}
          />
        </StyledComponentsThemeProviderLocal>
      </RuntimeProvider>
    </ErrorBoundary>
  );
};

const StyledComponentsThemeProviderLocal: React.FC<
  React.PropsWithChildren<any>
> = ({ children }) => {
  const theme = useTheme();

  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  );
};
