import { tomisFetch } from "@/api";

/** Add tag(s) to a transcript, using a user_id.
 *
 * Use this to add `check_availability_search` or other custom tags to a transcript.
 *
 * Because this is purely for analytics purposes, we don't need to return any data.
 */
export async function addTagToTranscript({
  userId,
  tags,
}: {
  /** Voiceflow userId (same as session ID) */
  userId: string;
  tags: string[];
}): Promise<{
  status: "success" | "error";
}> {
  const api = await tomisFetch<any>({
    endpoint: `/transcripts/${userId}/tags`,
    method: "PATCH",
    body: { reportTags: tags },
  });

  if (api.error || !api.body) {
    return { status: "error" };
  } else {
    return { status: "success" };
  }
}
