/** If TOMIS was an "enterprise" client we might have our own runtime, but
 * this is the general Voiceflow runtime URL. */
export const VF_API_URL = "https://general-runtime.voiceflow.com";

/** TOMIS Firebase Function "api" endpoint, set by environment variables */
export const TOMIS_CLOUD_API = import.meta.env.VITE_TOMIS_API_URL;

/** Demo project for testing - Arkansas River Tours */
export const VF_DEMO_PROJECT_ID = "6462b62562fa7f0007dcfab7";

/** If Chatbot is used on these domains,
 * it is a test conversation.
 *
 * - Test conversations should NOT be shown in chat logs
 * - Test conversation should include a reset button to restart the conversation
 */
export const TESTING_DOMAINS = [
  "localhost",
  "app.tomis.tech",
  "dev.tomis.tech",
  "staging.tomis.tech",
];

/** If Chatbot is used on localhost or in the TOMIS App, it is a test conversation.
 *
 * - Test conversations should NOT be shown in reporting
 */
export const IS_TEST_CONVERSATION = TESTING_DOMAINS.includes(
  window.location.hostname,
);
