import { SessionOptions } from "@/common";

export enum BroadcastType {
  SAVE_SESSION = "tomis:save_session",
  SAVE_TRANSCRIPT = "tomis:save_transcript",
  OPEN = "tomis:open",
  CLOSE = "tomis:close",
}

export interface BroadcastMessage {
  type: BroadcastType;
  payload?: unknown;
}

export interface SaveSession extends BroadcastMessage {
  type: BroadcastType.SAVE_SESSION;
  payload: SessionOptions;
}

export interface Open extends BroadcastMessage {
  type: BroadcastType.OPEN;
}
export interface Close extends BroadcastMessage {
  type: BroadcastType.CLOSE;
}

export interface SaveTranscript extends BroadcastMessage {
  type: BroadcastType.SAVE_TRANSCRIPT;
}

export type AnyMessage = SaveSession | Open | Close | SaveTranscript;

/** send messages so that other scripts can listen to them
 * https://developer.voiceflow.com/docs/chat-widget#events */
export const broadcast = (message: AnyMessage) => {
  const encodedMessage = JSON.stringify(message);
  window.postMessage(encodedMessage);
};
