import { useState } from "react";
import { CheckAvailabilityPayload } from "@tomis-tech/types";
import { CheckAvailabilityMessageProps } from "@tomis-tech/chat-ui";

import { SystemTurnProps, TurnProps } from "@/common";

interface UseCheckAvailabilityProps {
  /** All session turns */
  turns: TurnProps[];
}

/** Values returned from `useCheckAvailability` hook. */
export interface UseCheckAvailabilityReturn {
  /** Is Availability dialog visible? */
  isVisible: boolean;
  /** Show Availability window */
  show: () => void;
  /** Hide Availability window  */
  hide: () => void;
  /** Configuration */
  config: CheckAvailabilityPayload;
  /** True if the session includes a `check_availability` message */
  ready: boolean;
}

/** Check all turns for a check availability turn, use config if found.
 *
 * Will **always** return a valid configuration object (with default values),
 * even if the chat doesn't include a Check Availability message.
 */
export const useCheckAvailability = ({
  turns,
}: UseCheckAvailabilityProps): UseCheckAvailabilityReturn => {
  const [isVisible, setIsVisible] = useState(false);

  /** Reversed in order to get the config from most recent message */
  const turnsReversed = [...turns].reverse();

  const mostRecentCheckAvailabilityTurn = turnsReversed.find(
    (t) =>
      t.type === "system" &&
      (t as SystemTurnProps).messages.some(
        (m) => m.type === "check_availability",
      ),
  );

  const checkAvailabilityPayload = (
    (mostRecentCheckAvailabilityTurn as SystemTurnProps)?.messages.find(
      (m) => m.type === "check_availability",
    ) as CheckAvailabilityMessageProps
  )?.payload;

  const sanitized = sanitizeCheckAvailabilityPayload(
    checkAvailabilityPayload || {},
  );

  const mergedPayload: CheckAvailabilityPayload = {
    date: {
      ...DEFAULT_CHECK_AVAILABILITY_PAYLOAD.date,
      ...sanitized.date,
    },
    products: {
      ...DEFAULT_CHECK_AVAILABILITY_PAYLOAD.products,
      ...sanitized.products,
    },
    quantity: {
      ...DEFAULT_CHECK_AVAILABILITY_PAYLOAD.quantity,
      ...sanitized.quantity,
    },
  };

  return {
    isVisible,
    show: () => setIsVisible(true),
    hide: () => setIsVisible(false),
    config: mergedPayload,
    ready: Boolean(mostRecentCheckAvailabilityTurn),
  };
};

/** Make sure that payload from Voiceflow contains valid values,
 * to prevent errors when rendering the Check Availability window. */
function sanitizeCheckAvailabilityPayload(
  config: unknown,
): CheckAvailabilityPayload {
  const { date, products, quantity } = config as CheckAvailabilityPayload;

  let datePayload: CheckAvailabilityPayload["date"] = {};
  let productsPayload: CheckAvailabilityPayload["products"] = {
    all: [],
  };
  let quantityPayload: CheckAvailabilityPayload["quantity"] = {};

  if (typeof date === "object") {
    datePayload = {
      ...(typeof date.alias === "string" ? { alias: date.alias } : {}),
      ...(typeof date.description === "string"
        ? { description: date.description }
        : {}),
      ...(typeof date.min === "string" ? { min: date.min } : {}),
      ...(typeof date.max === "string" ? { max: date.max } : {}),
    };
  }

  if (typeof products === "object") {
    productsPayload = {
      ...(Array.isArray(products.all) ? { all: products.all } : { all: [] }),
      ...(typeof products.alias === "string" ? { alias: products.alias } : {}),
      ...(typeof products.description === "string"
        ? { description: products.description }
        : {}),
      ...(typeof products.defaultTag === "string"
        ? { defaultTag: products.defaultTag }
        : {}),
    };
  }

  if (typeof quantity === "object") {
    quantityPayload = {
      ...(typeof quantity.alias === "string" ? { alias: quantity.alias } : {}),
      ...(typeof quantity.description === "string"
        ? { description: quantity.description }
        : {}),
      ...(typeof quantity.min === "number" ? { min: quantity.min } : {}),
      ...(typeof quantity.max === "number" ? { max: quantity.max } : {}),
      ...(typeof quantity.default === "number"
        ? { default: quantity.default }
        : {}),
    };
  }

  return {
    date: datePayload,
    products: productsPayload,
    quantity: quantityPayload,
  };
}

const DEFAULT_CHECK_AVAILABILITY_PAYLOAD: CheckAvailabilityPayload = {
  products: {
    all: [],
    alias: "Products",
    description: "Search availability for multiple products.",
  },
  quantity: {
    min: 1,
    max: 99,
    default: 1,
    alias: "Quantity",
    description: "Select total number of participants.",
  },
  date: {
    alias: "Date",
    description: "Select a date",
  },
};
