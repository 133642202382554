import { FaCheckSquare } from "@react-icons/all-files/fa/FaCheckSquare";
import { FaRegSquare } from "@react-icons/all-files/fa/FaRegSquare";
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import { useTheme } from "@tomis-tech/chat-ui";

import { SelectTag, ContainerProducts, ItemProduct } from "./styled";
import { UseProductsReturn } from ".";

interface ProductsProps extends UseProductsReturn {}

/** Show availability selector in a selector that slides from the bottom  */
export const Products: React.FC<ProductsProps> = (products) => {
  const { colors } = useTheme();

  return (
    <div>
      <div style={{ position: "relative", width: "fit-content" }}>
        <FaCaretDown
          color={colors.grayDark}
          size={20}
          style={{
            position: "absolute",
            right: "6px",
            top: "6px",
            zIndex: "-1",
          }}
        />
        <SelectTag
          value={products.tagFilter}
          onChange={products.handleTagFilterChange}
        >
          <option value="All">All</option>
          {products.tags.map((tag) => (
            <option key={tag} value={tag}>
              {tag}
            </option>
          ))}
        </SelectTag>
      </div>
      <ContainerProducts>
        {products.filtered.map((p) => (
          <ItemProduct
            key={p.id}
            onClick={() => products.handleProductSelection(p)}
          >
            <div style={{ marginRight: "12px" }}>
              {p.isSelected ? (
                <FaCheckSquare color={colors.primary} size={20} />
              ) : (
                <FaRegSquare color={colors.grayMed} size={20} />
              )}
            </div>
            <div>{p.name}</div>
          </ItemProduct>
        ))}
      </ContainerProducts>
    </div>
  );
};
