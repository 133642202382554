import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

/** Format how Dates and Times should appear in the Check Availability carousel.
 * - If no time is included, only show the date.
 */
export function formatCheckAvailabilityTimes(dateTime: string) {
  const formatted = dayjs(dateTime).format(
    dateTime.includes("T") ? "ddd, MMM D [at] h:mm A" : "ddd, MMM D",
  );

  return formatted;
}
