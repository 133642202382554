/** Check if a variable is an object. This is more robust than typeof === 'object'
 *
 * Returns `false` if variable is `null` or `Array`
 */
export function isObject(variable: any): boolean {
  return (
    typeof variable === "object" &&
    variable !== null &&
    !Array.isArray(variable)
  );
}

/** Generate a random unique id using `crypto.randomUUID`.
 * Learn more: <https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID> */
export function createUniqueId(): string {
  return crypto.randomUUID();
}
