import { FeedbackData } from "@tomis-tech/chat-ui";

import { tomisFetch } from "@/api";
import { SystemTurnProps } from "@/common";

/** Store chatbot user feedback in Database */
export async function storeFeedback({
  transcriptId,
  turn,
  feedback,
}: {
  transcriptId: string;
  turn: SystemTurnProps;
  feedback: FeedbackData;
}): Promise<{
  status: "success" | "error";
}> {
  const api = await tomisFetch<any>({
    endpoint: "/chatFeedback",
    method: "POST",
    body: {
      transcriptId,
      feedback,
      turn: {
        ...turn,
        // convert timestamp to ISO string for API
        timestamp: new Date(turn.timestamp).toISOString(),
      },
    },
  });

  if (api.error || !api.body) {
    return { status: "error" };
  } else {
    return { status: "success" };
  }
}
