import React, { ErrorInfo, ReactNode } from "react";

import { trackEvent, TomisEvent } from "@/tracking";

interface ErrorBoundaryProps {
  children: ReactNode;
  /** Component to show on error */
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/** Basic `<ErrorBoundary>` component to catch crashes.
 *
 * This can be placed anywhere within the application, at
 * minimum it should be placed at the App root.
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    trackEvent(TomisEvent.Crashed);
    // TODO: log error to Sentry
    console.error("Chat Widget crashed: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}
