import { CheckAvailabilityPayload } from "@tomis-tech/types";
import { MessageType } from "@tomis-tech/chat-ui";

import { RuntimeState } from "@/contexts/RuntimeContext/useRuntimeState";
import { trackEvent } from "@/tracking";
import { createUniqueId } from "@/utils";
import { ProductAvailabilityReturnType } from "@/services/checkAvailability";

import { ProductSelection } from "./Products";
import { formatCheckAvailabilityTimes } from "./utils";

/** `runtime.interact` to trigger `check_availability_search` intent.
 *
 * This message is added before the async request to TOMIS API. It's
 * used to show the user what they searched for.
 *
 * Also:
 * - Track Event "check_availability_search" (Google Analytics)
 */
export async function addCheckAvailabilityRequestTurn({
  runtime,
  config,
  products,
  dateEnd,
  quantity,
  dateStart,
}: {
  runtime: RuntimeState["api"];
  config: CheckAvailabilityPayload;
  products: ProductSelection[];
  quantity: number;
  dateStart: string;
  dateEnd: string;
}) {
  const productNames = products.map((p) => p.name);

  const messageText = `**Availability Search:**\n\n*${
    config.products.alias
  }:* \n${productNames.map((p) => "- " + p).join("\n")}\n\n*${
    config.date.alias
  }:* \n - Start: ${formatCheckAvailabilityTimes(
    dateStart,
  )}\n - End: ${formatCheckAvailabilityTimes(dateEnd)}\n\n*${
    config.quantity.alias
  }:* ${quantity}`;

  await runtime.interact({
    type: "intent",
    payload: {
      intent: { name: "check_availability_search" },
      message: messageText,
    },
  });

  trackEvent("check_availability_search", {
    product_names: productNames.join(", "),
    products,
    date_start: dateStart,
    date_end: dateEnd,
    quantity,
  });
}

/** `runtime.interact` to create availability carousel.
 *
 * If no availabilities, show message "I'm sorry, I couldn't find any availability for your request."
 */
export const addCheckAvailabilitySuccessTurn = async ({
  availability,
  runtime,
  products,
}: {
  availability: ProductAvailabilityReturnType;
  runtime: RuntimeState["api"];
  products: ProductSelection[];
}) => {
  /** `true` if any of the products queried have availability */
  let hasAvailability = false;
  const cards: any[] = [];

  for (const product in availability) {
    const availabilities = availability[product];
    const matchedProduct = products.find((p) => p.id === product);

    const buttons: any[] = availabilities.map((a) => ({
      name: formatCheckAvailabilityTimes(a.datetime),
      url: a.booking_url,
    }));

    cards.push({
      image: matchedProduct?.imageUrl,
      title: matchedProduct!.name,
      description:
        availabilities.length > 0
          ? matchedProduct!.description || ""
          : "No availability",
      buttons,
    });

    if (availabilities.length > 0 && hasAvailability === false) {
      hasAvailability = true;
    }
  }

  // Sort cards by number of availabilities
  cards.sort((a, b) => b.buttons!.length - a.buttons!.length);

  const carouselTrace = {
    type: MessageType.CHECK_AVAILABILITY_CAROUSEL,
    payload: {
      cards,
    },
  };

  const textTrace = {
    type: MessageType.TEXT,
    payload: {
      message: hasAvailability
        ? "Okay, this is what I found..."
        : "I'm sorry, I couldn't find any availability for your request. Please try searching for a different date or product.",
    },
  };

  const searchAgainMessage = {
    type: MessageType.CHECK_AVAILABILITY_AGAIN,
    payload: {},
  };

  const traces: any[] = [textTrace];

  if (hasAvailability) {
    traces.push(carouselTrace);
  }

  traces.push(searchAgainMessage);

  await runtime.interact({
    type: "intent",
    payload: {
      intent: { name: "check_availability_finished" },
      message: traces,
    },
  });

  if (hasAvailability) {
    trackEvent("check_availability_availability_found", {
      products,
    });
  } else {
    trackEvent("check_availability_no_availability_found", {
      products,
    });
  }
};

/** If there's an error fetching product availability, show error message */
export const addCheckAvailabilityErrorTurn = async ({
  runtime,
}: {
  runtime: RuntimeState["api"];
}) => {
  const searchAgainMessage = {
    type: MessageType.CHECK_AVAILABILITY_AGAIN,
    payload: {},
    onClick: () => {},
  };

  const textTrace = {
    type: MessageType.TEXT,
    payload: {
      message:
        "I'm sorry, an error occurred while searching for availability. Please try again.",
    },
  };

  const turnId = createUniqueId();

  await runtime.interact({
    type: "intent",
    payload: {
      intent: { name: "check_availability_finished" },
      message: [textTrace, searchAgainMessage],
    },
  });

  trackEvent("check_availability_error", {
    turn_id: turnId,
  });
};
