import { useContext } from "react";
import { ChatWidgetConfiguration } from "@tomis-tech/types";
import {
  SystemMessageProps,
  ResponseActionProps,
  MessageType,
  HeaderActionProps,
  SystemTurn,
  UserTurn,
  Chat,
  FeedbackProps,
  FeedbackRating,
} from "@tomis-tech/chat-ui";

import {
  CheckAvailabilityContext,
  RuntimeStateAPIContext,
  RuntimeStateContext,
} from "@/contexts";
import { TomisEvent, trackEvent } from "@/tracking";
import { ChatUser, SessionStatus, SystemTurnProps, TurnType } from "@/common";
import { useCheckAvailability } from "@/hooks";
import { CheckAvailabilityWrapper } from "@/components/CheckAvailability";
import { SystemTurnWithFeedback } from "@/contexts/RuntimeContext/useRuntimeState";

type ChatOpenProps = Pick<
  ChatWidgetConfiguration,
  "name" | "image" | "description" | "watermark"
> & {
  embed: boolean;
  feedback: FeedbackProps["mode"];
  user: ChatUser;
};

/** The entire **OPEN** chat widget */
export const ChatOpen: React.FC<ChatOpenProps> = ({
  description,
  image,
  name,
  watermark,
  embed,
  feedback,
}) => {
  const runtimeApi = useContext(RuntimeStateAPIContext);
  const runtimeState = useContext(RuntimeStateContext);
  const availability = useCheckAvailability({
    turns: runtimeState.session.turns,
  });

  function closeWidget() {
    runtimeApi.close();
    availability.hide();
    trackEvent(TomisEvent.WindowClosed);
  }

  async function restartSession() {
    await runtimeApi.launch();
    availability.hide();
    trackEvent(TomisEvent.SessionRestarted);
  }

  async function handleSend(text: string) {
    try {
      await runtimeApi.reply(text);
      trackEvent(TomisEvent.MessageSentCustomer, { message_user: text });
      return true;
    } catch (error) {
      // TODO: improve this error handling
      return false;
    }
  }

  /** Add click listener to "check_availability" and "check_availability_again"
   * custom system response messages */
  function addClickListenerToCustomSystemResponseMessages(
    message: SystemMessageProps,
  ): SystemMessageProps {
    if (message.type === MessageType.CHECK_AVAILABILITY) {
      return {
        ...message,
        onClick: availability.show,
      };
    } else if (message.type === MessageType.CHECK_AVAILABILITY_AGAIN) {
      return {
        ...message,
        onClick: availability.show,
      };
    } else if (message.type === MessageType.CHECK_AVAILABILITY_CAROUSEL) {
      return {
        ...message,
        cards: message.cards.map((card) => {
          return {
            ...card,
            actions: (card.actions || []).map((action) => {
              return {
                ...action,
                onClick: () => {
                  trackEvent("check_availability_link_click");
                },
              };
            }),
          };
        }),
      };
    }
    return message;
    // No additional click handler at this time
  }

  /** Handle clicking on SystemResponse "actions" (aka buttons) */
  function addClickListenerToSystemResponseActions(
    action: ResponseActionProps,
  ): ResponseActionProps {
    if (!runtimeApi) return action;

    if (action.request) {
      // This is a normal quick reply button
      return {
        ...action,
        onClick: () => {
          runtimeApi.interact(action.request!, action.name);
          trackEvent("message_sent_customer", {
            message_user: name,
            quick_reply: true,
          });
        },
      };
    } else if (action.url) {
      // This is a link to be opened by a <a> tag, add tracking here
      return {
        ...action,
        onClick: () => {
          if (action.type === "check_availability_link") {
            trackEvent("check_availability_link_click");
          }
        },
      };
    }

    // no click handler for this action
    return action;
  }

  /** Callback function to execute when animation for last
   * system message has been shown on the screen */
  function onSystemMessageAnimationEnd(messages: SystemMessageProps[]) {
    if (messages.length === 0) return; // no animation

    const lastMessage = messages[messages.length - 1];

    if (lastMessage.type === MessageType.CHECK_AVAILABILITY) {
      return availability.show();
    }

    if (lastMessage.type === MessageType.END) {
      return runtimeApi.setStatus(SessionStatus.ENDED);
    }
  }

  /** Add interactivity to feedback component, if enabled */
  function addFeedbackActions(
    turn: SystemTurnProps,
  ): FeedbackProps | undefined {
    if (feedback === "disabled") return undefined;

    const turnWithFeedback: SystemTurnWithFeedback = {
      ...turn,
      feedback: {
        mode: feedback,
        data: { ...(turn.feedback?.data || {}) },
      },
    };

    const updatedFeedback = turnWithFeedback.feedback;

    // convert string timestamps (from local storage) to JavaScript Date objects
    updatedFeedback.data.createdAt = updatedFeedback.data.createdAt
      ? new Date(updatedFeedback.data.createdAt)
      : undefined;
    updatedFeedback.data.resolvedAt = updatedFeedback.data.resolvedAt
      ? new Date(updatedFeedback.data.resolvedAt)
      : undefined;

    if (feedback === "edit") {
      updatedFeedback.onSaveFeedback = (m: string) =>
        runtimeApi.feedback.saveMessage({ turn: turnWithFeedback, message: m });
      updatedFeedback.onSaveRating = (r: FeedbackRating | undefined) =>
        runtimeApi.feedback.saveRating({ turn: turnWithFeedback, rating: r });
    } else if (feedback === "review") {
      updatedFeedback.onSaveResolved = (r: boolean) =>
        runtimeApi.feedback.saveResolved({
          turn: turnWithFeedback,
          isResolved: r,
        });
    } else if (feedback === "view") {
      // do nothing
    }
    return updatedFeedback;
  }

  const headerActions: HeaderActionProps[] = [
    {
      icon: "refresh",
      size: 16,
      onClick: restartSession,
    },
  ];

  if (!embed) {
    headerActions.push({
      icon: "minus",
      size: 16,
      onClick: closeWidget,
    });
  }

  const hasEnded = runtimeApi.isStatus(SessionStatus.ENDED);

  const extra = <CheckAvailabilityWrapper {...availability} />;

  // @ts-expect-error - typescript doesn't like the lack of return after last conditional
  const turns: Array<UserTurn | SystemTurn> = runtimeState.session.turns
    .filter((t) => t.type === TurnType.SYSTEM || t.type === TurnType.USER)
    .map((turn) => {
      if (turn.type === "system") {
        return {
          id: turn.id,
          type: "system",
          props: {
            actions: (turn.actions || []).map(
              addClickListenerToSystemResponseActions,
            ),
            avatar: image,
            isLast: turn.isLast,
            messages: turn.messages.map(
              addClickListenerToCustomSystemResponseMessages,
            ),
            timestamp: turn.timestamp,
            useAnimation: true,
            onEndAnimation: onSystemMessageAnimationEnd,
            id: turn.id,
            feedback: addFeedbackActions(turn),
          },
        } as SystemTurn;
      } else if (turn.type === "user") {
        return {
          id: turn.id,
          type: "user",
          props: {
            message: turn.message,
            timestamp: turn.timestamp,
          },
        } as UserTurn;
      }
    });

  return (
    <CheckAvailabilityContext.Provider value={availability}>
      <Chat
        description={description}
        handleSend={handleSend}
        hasEnded={hasEnded}
        headerActions={headerActions}
        image={image}
        name={name}
        restartSession={restartSession}
        turns={turns}
        watermark={watermark}
        extra={extra}
        indicator={runtimeState.indicator}
      />
    </CheckAvailabilityContext.Provider>
  );
};
