import React from "react";
import ReactDOM from "react-dom";
import { StyleSheetManager } from "styled-components";
import { ChatWidgetConfiguration } from "@tomis-tech/types";
import { FeedbackProps, ThemeProvider } from "@tomis-tech/chat-ui";

import { initialize } from "@/services/initialize";
import {
  trackEvent,
  TomisEvent,
  initializeTracking,
  setSentryTags,
  initSentry,
} from "@/tracking";
import { injectStylesShadowRoot } from "@/styles";
import { ChatUser, SessionStatus } from "@/common";

import { App } from "./App.tsx";

/** HTML element id that holds the shadow root and entire chat widget.
 *
 * Same "id" will be used by normal chat widget and embed mode. */
const TOMIS_CHAT_ID = "tomis-chat-widget";

/** Create chat widget in Shadow Root.
 *
 * This function will create a Shadow Root and attach it to the document body.
 * It will then render the chat widget inside the Shadow Root.
 * We are using a Shadow Root to prevent the chat widget from interfering with
 * the styles of the host page. This requires a small work around to make
 * `styled-components` attach it's stylesheet inside the Shadow Root, which you can see below.
 *
 * @returns A promise that resolves when the widget is ready to use.
 */
async function createWidget(
  config: ChatWidgetConfiguration,
  user: ChatUser,
  /** If `true` render chat widget in element */
  embed: boolean,
  feedback: FeedbackProps["mode"],
): Promise<any> {
  let root: HTMLElement;

  if (embed) {
    const embeddedElement = document.getElementById(TOMIS_CHAT_ID);
    if (!embeddedElement) {
      throw new Error(
        "Element with id 'tomis-chat-widget' not found. Please add a <div> with id 'tomis-chat-widget' to your page.",
      );
    } else {
      console.warn(
        "Rendering TOMIS ChatBot in embedded mode: ",
        embeddedElement,
      );
    }

    root = embeddedElement;
    root.id = TOMIS_CHAT_ID;
  } else {
    root = document.createElement("div");
    root.id = TOMIS_CHAT_ID;
    document.body.appendChild(root);
  }

  const shadow = root.attachShadow({ mode: "open" });
  const styleSlot = document.createElement("section");
  shadow.appendChild(styleSlot);
  const renderIn = document.createElement("div");
  renderIn.id = TOMIS_CHAT_ID + "-app";
  styleSlot.appendChild(renderIn);

  // Make sure the chat widget takes up the full size of embed element
  if (embed) {
    styleSlot.setAttribute("style", "height: 100%; width: 100%;");
    renderIn.setAttribute("style", "height: 100%; width: 100%;");
  }

  // Apply CSS styles inside Shadow Root
  injectStylesShadowRoot(shadow);

  initSentry();

  return new Promise((resolve) => {
    ReactDOM.render(
      <React.StrictMode>
        <StyleSheetManager target={styleSlot}>
          <ThemeProvider
            primaryColor={config.color}
            styleSheetTarget={styleSlot}
          >
            <App
              config={config}
              onReady={() => resolve(null)}
              user={user}
              feedback={feedback}
              embed={embed}
            />
          </ThemeProvider>
        </StyleSheetManager>
      </React.StrictMode>,
      renderIn,
    );
  });
}

/** This function is the entry point for JavaScript loaded on the client site.
 *
 * 1. Fetch API to get configuration & validate bot for this domain
 * 2. If valid, create iframe and append to DOM. If invalid, log to console and do nothing.
 * 3. Setup tracking
 *
 * @returns Promise that resolves when React chat widget has been created and
 * `window.tomis.chat` API has been initialized */
export async function load({
  id,
  settings = {},
  user = {},
  embed = false,
  feedback = "disabled",
}: {
  /** TOMIS site-slug */
  id: string;
  /** Chat widget configuration for this particular page.
   *
   * Any settings provided here will override settings from the database.
   * This give us granular control over the chat widget on a per-page basis,
   * as well as helps with testing.
   */
  settings?: Partial<ChatWidgetConfiguration>;
  /** Optional user information to pass to Voiceflow.
   *
   * We will use this in the TOMIS App to identify the user chatting w/ the ChatBot.
   */
  user?: ChatUser;
  /** If true, chat widget will be embedded in the page instead of a floating dialog */
  embed?: boolean;
  /** Feedback mode for chat widget */
  feedback?: FeedbackProps["mode"];
}) {
  if (isChatWidgetOnPage()) {
    console.warn(
      "TOMIS ChatBot is already loaded on this page. Please call `window.tomis.chat.load` only once.",
    );
    return;
  }

  initializeTracking();

  const results = await initialize(id, settings);

  if (!results.success || !results.config) {
    return trackEvent(TomisEvent.LoadPrevented, {
      error: results.errorMessage,
    });
  }

  setSentryTags({
    site: id,
    hostname: window.location.hostname,
    voiceflow_project_id: results.config.projectId,
  });

  // Create React App
  await createWidget(results.config, user, embed, feedback);

  setSentryTags({
    voiceflow_user_id: window.tomis.chat.session.userID,
  });

  trackEvent(TomisEvent.Loaded, results.config);

  if (!results.config.hideWelcomeMessagesPreview) {
    showProactiveMessages(
      results.config.welcomeMessages,
      results.config.welcomeMessagesDelay,
    );
  }
}

/** Check if chat widget is already on the page */
function isChatWidgetOnPage() {
  const chatWidget = document.getElementById(TOMIS_CHAT_ID);

  const shadowRoot = chatWidget?.shadowRoot;

  if (!shadowRoot) {
    return false;
  }

  const reactApp = shadowRoot.getElementById(TOMIS_CHAT_ID + "-app");

  return Boolean(reactApp);
}

/** If chat widget hasn't been opened yet, show proactive "welcome" messages */
function showProactiveMessages(messages: string[] = [], delay: number = 0) {
  window.tomis.chat.proactive.clear();
  const messagesToShow = messages.map((message) => ({
    type: "text",
    payload: { message },
  }));
  setTimeout(() => {
    // Another check to make sure chat widget has not been opened yet
    if (window.tomis.chat.session.status === SessionStatus.IDLE) {
      // @ts-ignore
      window.tomis.chat.proactive.push(...messagesToShow);
    }
  }, delay);
}

// Expose `window.tomis` object to window to interact w/ TOMIS chat widget
window.tomis = {
  chat: {
    load: load,
    open: () => {},
    close: () => {},
    interact: () => {},
    show: () => {},
    hide: () => {},
    proactive: {
      clear: () => {},
      push: () => {},
    },
    session: {
      userID: "",
      status: SessionStatus.IDLE,
    },
    id: "",
    config: {},
    user: {},
  },
};
