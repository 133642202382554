import { createContext } from "react";

import { UseCheckAvailabilityReturn } from "@/hooks";

/** Check Availability configuration (open, closed, products, etc.) */
export const CheckAvailabilityContext =
  createContext<UseCheckAvailabilityReturn>({
    isVisible: false,
    show: () => {},
    hide: () => {},
    config: { date: {}, products: { all: [] }, quantity: {} },
    ready: false,
  });
