import { useState } from "react";
import { BaseRequest } from "@voiceflow/base-types";
import { isTextRequest } from "@voiceflow/base-types/build/cjs/request";
import {
  ActionType,
  PublicVerify,
  Trace,
  TraceDeclaration,
} from "@voiceflow/sdk-runtime";
import {
  DEFAULT_MESSAGE_DELAY,
  FeedbackData,
  FeedbackProps,
  FeedbackRating,
} from "@tomis-tech/chat-ui";

import {
  Assistant,
  RuntimeOptions,
  SendMessage,
  SessionOptions,
  SessionStatus,
  SystemTurnProps,
  TurnProps,
  TurnType,
} from "@/common";
import type {
  MESSAGE_TRACES,
  RuntimeMessage,
} from "@/contexts/RuntimeContext/messages";
import { useStateRef } from "@/hooks";
import {
  handleActions,
  broadcast,
  BroadcastType,
  getSession,
  saveSession,
  createUniqueId,
  getStorageTranscript,
} from "@/utils";
import { useNoReply } from "./useNoReply";
import { createContext, useRuntimeAPI } from "./useRuntimeAPI";
import { storeFeedback } from "@/services";
import { trackEvent } from "@/tracking";

interface FeedbackWithData extends FeedbackProps {
  data: FeedbackData;
}

/** System turn that is guaranteed to have feedback and feedback data */
export interface SystemTurnWithFeedback extends SystemTurnProps {
  feedback: FeedbackWithData;
}

export interface Settings {
  assistant: Assistant;
  config: RuntimeOptions<PublicVerify>;
  traceHandlers?: typeof MESSAGE_TRACES;
  /** Should we be collecting feedback from users? */
  feedback?: FeedbackProps["mode"];
}

const DEFAULT_SESSION_PARAMS = {
  turns: [],
  startTime: Date.now(),
  status: SessionStatus.IDLE,
};

export const useRuntimeState = ({
  assistant,
  config,
  traceHandlers = [],
  feedback = "disabled",
}: Settings) => {
  const [isOpen, setOpen] = useState(false);

  const [session, setSession, sessionRef] = useStateRef<
    Required<SessionOptions>
  >(() => ({
    ...DEFAULT_SESSION_PARAMS,
    // retrieve stored session
    ...getSession(
      assistant.persistence,
      config.verify.projectID,
      config.userID,
    ),
  }));

  const [indicator, setIndicator] = useState(false);

  const { clearNoReplyTimeout, setNoReplyTimeout } = useNoReply(() => ({
    interact,
    isStatus,
  }));

  const noReplyHandler: TraceDeclaration<RuntimeMessage, any> = {
    canHandle: ({ type }) => type === ActionType.NO_REPLY,
    handle: ({ context }, trace: Trace.NoReplyTrace) => {
      if (trace.payload?.timeout) {
        // messages take 1 second to animate in, on top of the delay
        const messageDelays = context.messages.reduce(
          (acc, message) =>
            acc + (message.delay ?? 1000) + DEFAULT_MESSAGE_DELAY,
          0,
        );
        const timeout = trace.payload.timeout * 1000 + messageDelays;

        setNoReplyTimeout(timeout);
      }
      return context;
    },
  };

  const runtime = useRuntimeAPI({
    ...config,
    ...session,
    traceHandlers: [noReplyHandler, ...traceHandlers],
  });

  // status management
  const setStatus = (status: SessionStatus) => {
    setSession((prev) => (prev.status === status ? prev : { ...prev, status }));
  };
  const isStatus = (status: SessionStatus) => {
    return sessionRef.current.status === status;
  };

  // turn management
  const setTurns = (action: (turns: TurnProps[]) => TurnProps[]) => {
    setSession((prev) => ({ ...prev, turns: action(prev.turns) }));
  };

  /** Add a turn to local state  */
  const addTurn = (turn: TurnProps) => {
    // Add feedback settings to all turns
    if (turn.type === TurnType.SYSTEM) {
      const systemTurn: SystemTurnProps = {
        ...turn,
        feedback: { mode: feedback, data: {} },
      };

      setTurns((prev) => [...prev, systemTurn]);
    } else {
      // don't modify turn at all
      setTurns((prev) => [...prev, turn]);
    }
  };

  /** Ensure transcript has been created via Voiceflow API.
   * @returns The transcript ID */
  async function ensureTranscriptId(): Promise<string> {
    let transcriptId = window.tomis.chat.transcript?._id;
    if (!transcriptId) {
      const transcript = await runtime.saveTranscript();
      transcriptId = transcript._id;
    }
    return transcriptId;
  }

  /** ChatBot user leaves a message for chatbot maintainers to fix.
   *
   * Will update the turn in window storage and local state. */
  async function saveFeedbackMessage({
    turn,
    message,
  }: {
    /** Turn ID to update in local storage */
    turn: SystemTurnWithFeedback;
    /** The message left by chatbot user */
    message: string;
  }) {
    if (!turn) return;

    turn.feedback.data.message = message;
    // @ts-expect-error - will be saved to local storage as string anyways
    turn.feedback.data.createdAt = new Date().toISOString();
    turn.feedback.data.createdBy = window.tomis?.chat?.user?.name;
    turn.feedback.data.createdByEmail = window.tomis?.chat?.user?.email;
    turn.feedback.data.createdByTomisId = window.tomis?.chat?.user?.id;

    editTurn(turn.id, turn);

    const transcriptId = await ensureTranscriptId();

    trackEvent("feedback_message", {
      ...turn.feedback.data,
      turn: turn.id,
      transcript: transcriptId,
    });

    // We aren't catching errors, this isn't super critical as we aren't showing errors to the user
    await storeFeedback({
      feedback: turn.feedback.data,
      transcriptId,
      turn,
    });
  }

  /** Chatbot user leaves "positive" or "negative" feedback by clicking thumbs up or down.
   *
   * Use `undefined` to clear the rating. */
  async function saveFeedbackRating({
    turn,
    rating,
  }: {
    turn: SystemTurnWithFeedback;
    /** Use `undefined` to clear the rating */
    rating?: FeedbackRating;
  }) {
    if (!turn) return;

    turn.feedback.data.rating = rating;
    // @ts-expect-error - will be saved to local storage as string anyways
    turn.feedback.data.createdAt = new Date().toISOString();
    turn.feedback.data.createdBy = window.tomis?.chat?.user?.name;
    turn.feedback.data.createdByEmail = window.tomis?.chat?.user?.email;
    turn.feedback.data.createdByTomisId = window.tomis?.chat?.user?.id;

    editTurn(turn.id, turn);

    const transcriptId = await ensureTranscriptId();

    if (rating === "positive") {
      trackEvent("feedback_positive", {
        ...turn.feedback.data,
        turn: turn.id,
        transcript: transcriptId,
      });
    } else if (rating === "negative") {
      trackEvent("feedback_negative", {
        ...turn.feedback.data,
        turn: turn.id,
        transcript: transcriptId,
      });
    }

    // We aren't catching errors, this isn't super critical as we aren't showing errors to the user
    await storeFeedback({
      feedback: turn.feedback.data,
      transcriptId,
      turn,
    });
  }

  /** Chat Logs reviewer marks feedback as resolved (usually after fixing issue).  */
  async function saveFeedbackResolved({
    turn,
    isResolved,
  }: {
    turn: SystemTurnWithFeedback;
    isResolved: boolean;
  }) {
    if (!turn) return;

    turn.feedback.data.resolved = isResolved;
    // @ts-expect-error - will be saved to local storage as string anyways
    turn.feedback.data.resolvedAt = isResolved
      ? new Date().toISOString()
      : undefined;
    turn.feedback.data.resolvedBy = isResolved
      ? window.tomis?.chat?.user?.name
      : undefined;
    turn.feedback.data.resolvedByTomisId = isResolved
      ? window.tomis?.chat?.user?.id
      : undefined;

    editTurn(turn.id, turn);

    const transcriptId = await ensureTranscriptId();

    trackEvent("feedback_resolved", {
      ...turn.feedback.data,
      turn: turn.id,
      transcript: transcriptId,
    });

    // We aren't catching errors, this isn't super critical as we aren't showing errors to the user
    await storeFeedback({
      feedback: turn.feedback.data,
      transcriptId,
      turn,
    });
  }

  /** Modify a turn & save to window storage */
  const editTurn = (id: string, turn: TurnProps) => {
    setTurns((prev) => prev.map((t) => (t.id === id ? { ...t, ...turn } : t)));
    saveSession(
      assistant.persistence,
      config.verify.projectID,
      sessionRef.current,
    );
  };

  const reset = () => setTurns(() => []);

  const interact: SendMessage = async (
    action: BaseRequest.BaseRequest,
    message?: string,
  ) => {
    clearNoReplyTimeout();

    if (sessionRef.current.status === SessionStatus.ENDED) return;

    // create a transcript on the first turn, do this async
    if (sessionRef.current.turns.length === 1) {
      runtime.saveTranscript();
    }

    handleActions(action);

    const userMessage =
      message || (isTextRequest(action) ? action.payload : null);
    if (userMessage) {
      addTurn({
        id: createUniqueId(),
        type: TurnType.USER,
        message: userMessage,
        timestamp: Date.now(),
      });
    }

    setIndicator(true);
    const context = await runtime.interact(action).catch((error) => {
      // TODO: better define error condition
      console.error(error);
      return createContext();
    });
    setIndicator(false);

    addTurn({
      id: createUniqueId(),
      type: TurnType.SYSTEM,
      timestamp: Date.now(),
      ...context,
    });

    saveSession(
      assistant.persistence,
      config.verify.projectID,
      sessionRef.current,
    );
  };

  const launch = async (): Promise<void> => {
    if (sessionRef.current.turns.length) reset();

    setStatus(SessionStatus.ACTIVE);
    await interact(
      config.launch?.event ?? {
        type: BaseRequest.RequestType.LAUNCH,
        payload: null,
      },
    );
  };

  const reply = async (message: string): Promise<void> =>
    interact({ type: BaseRequest.RequestType.TEXT, payload: message });

  const open = async () => {
    broadcast({ type: BroadcastType.OPEN });
    setOpen(true);

    if (isStatus(SessionStatus.IDLE)) {
      await launch();
    }
  };

  const close = () => {
    broadcast({ type: BroadcastType.CLOSE });
    setOpen(false);
  };

  const transcript = getStorageTranscript(
    localStorage,
    config.verify.projectID,
  );

  return {
    state: {
      session,
      isOpen,
      indicator,
      transcript,
    },
    api: {
      launch,
      reply,
      open,
      interact,
      close,
      addTurn,
      feedback: {
        /** hCatBot user leaves a message for chatbot maintainers to fix. */
        saveMessage: saveFeedbackMessage,
        /** Chatbot user leaves "positive" or "negative" feedback by clicking thumbs up or down. */
        saveRating: saveFeedbackRating,
        /** Chat logs reviewer has fixed the chatbot user complaint */
        saveResolved: saveFeedbackResolved,
      },
      setStatus,
      isStatus,
      reset,
      editTurn,
      // these are meant to be static, so bundling them with the API
      assistant,
      config,
    },
  };
};

export type RuntimeState = ReturnType<typeof useRuntimeState>;
