import {
  CheckAvailabilityDuration,
  ProductListing,
  ReservationSystemProductInfo,
  TranscriptTag,
} from "@tomis-tech/types";

import { ProductSelection } from "@/components/CheckAvailability/Products";
import { addTagToTranscript } from "./updateTranscript";

interface CheckAvailabilityReturnType {
  error: boolean;
  /** Availability for each product */
  data: ProductAvailabilityReturnType;
  /** Products, augmented w/ description and image url */
  products: ProductSelection[];
}

/** Get Product Availability from TOMIS backend.
 *
 * This endpoint is unprotected and does not require an API key.
 *
 * @returns Will always resolve/fail gracefully, no need to catch errors */
export const getProductAvailabilityAndDetails = async ({
  products,
  dateStart,
  dateEnd,
  quantity,
}: {
  /** Array of product IDs */
  products: ProductSelection[];
  /** Format YYYY-MM-DD */
  dateStart: string;
  /** Format YYY-MM-DD */
  dateEnd: string;
  /** Number of participants */
  quantity: number;
}): Promise<CheckAvailabilityReturnType> => {
  /** We may need to make two separate API requests if products of different
   * durations are selected. */
  const durationRequests: {
    [key in CheckAvailabilityDuration]: string[];
  } = {
    fixed: [],
    variable: [],
  };

  products.forEach((product) => {
    durationRequests[product.duration].push(product.id);
  });

  const promises: {
    [key in CheckAvailabilityDuration]: Promise<
      Pick<CheckAvailabilityReturnType, "data" | "error">
    >;
  } = {
    fixed: Promise.resolve({ error: false, data: {}, products }),
    variable: Promise.resolve({ error: false, data: {}, products }),
  };

  // If no products are of a duration, don't do an api request for that duration
  if (durationRequests.fixed.length > 0) {
    promises.fixed = fetchAvailability({
      productIds: durationRequests.fixed,
      dateStart,
      dateEnd,
      quantity,
      duration: "fixed",
    });
  }
  if (durationRequests.variable.length > 0) {
    promises.variable = fetchAvailability({
      productIds: durationRequests.variable,
      dateStart,
      dateEnd,
      quantity,
      duration: "variable",
    });
  }

  const productDetailsPromises = products.map((p) => getProductDetails(p.id));

  const [fixed, variable, ...productDetails] = await Promise.all([
    promises.fixed,
    promises.variable,
    ...productDetailsPromises,
  ]);

  const productsWithDetails = products.map((p, i) => ({
    ...p,
    ...productDetails[i],
  }));

  return {
    error: fixed?.error || variable?.error,
    data: {
      ...(fixed?.data || {}),
      ...(variable?.data || {}),
    },
    products: productsWithDetails,
  };
};

/** Data format returned from Availability API endpoint */
export type ProductAvailabilityReturnType = {
  [key: string]: {
    datetime: string;
    quantity: number;
    booking_url: string;
  }[];
};

/** Get product availability for several products.
 *
 * All products must be of the same duration ("fixed" or "variable").
 */
export const fetchAvailability = async ({
  productIds,
  dateStart,
  dateEnd,
  quantity,
  duration,
}: {
  /** Array of product IDs */
  productIds: string[];
  /** Format YYYY-MM-DD */
  dateStart: string;
  /** Format YYY-MM-DD */
  dateEnd: string;
  /** Number of participants */
  quantity: number;
  duration: "fixed" | "variable";
}): Promise<{
  error: boolean;
  data: ProductAvailabilityReturnType;
}> => {
  const siteSlug = window.tomis.chat.id;
  const url = `https://app.tomis.tech/api/${siteSlug}/products-availability/?type=${duration}`;

  const requestBody = {
    product_ids: productIds,
    date_start: dateStart,
    date_end: dateEnd,
    quantity,
  };

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((r) => {
      if (r.ok) return r.json();
      throw new Error(r.status + r.statusText);
    })
    .then((data: ProductAvailabilityReturnType) => {
      return { error: false, data };
    })
    .catch((error) => {
      console.error("Error fetching product availability", error);
      const data: ProductAvailabilityReturnType = {};
      productIds.forEach((id) => (data[id] = []));

      return { error: true, data };
    });
};

/** Get Product Image and Description from TOMIS backend. For availability carousel.
 *
 * Will always resolve/fail gracefully, no need to catch errors.
 *
 * If failure, `imageUrl` and `description` will be empty strings. */
export async function getProductDetails(
  productId: string,
): Promise<Pick<ProductListing, "description" | "id" | "imageUrl">> {
  const siteSlug = window.tomis.chat.id;
  const fetchUrl = `https://app.tomis.tech/api/${siteSlug}/products/?product_id=${productId}`;

  return fetch(fetchUrl)
    .then((r) => {
      if (r.ok) return r.json();
      throw new Error(r.status + r.statusText);
    })
    .then((data: ReservationSystemProductInfo) => {
      /** Response body should be exactly what we need! */
      return {
        description: data.description || "",
        id: productId,
        imageUrl: data.image_url || "",
      };
    })
    .catch((error) => {
      console.error("Error fetching product details", error);
      return {
        id: productId,
        imageUrl: "",
        description: "",
      };
    });
}

/** Add `check_availability_search` tag to transcript for analytics purposes */
export async function addCheckAvailabilitySearchTagToTranscript() {
  const userId = window.tomis?.chat?.session?.userID;

  if (!userId) return;

  await addTagToTranscript({
    userId,
    tags: [TranscriptTag.CheckAvailabilitySearch],
  });
}
