import { Spacing, ChatWidgetConfiguration } from "@tomis-tech/types";

import { tomisFetch } from "@/api";
import { isObject } from "@/utils";
import { IS_TEST_CONVERSATION } from "@/constants";

/** Fetch TOMIS API to get ChatBot configuration.
 *
 * Then sanitize and merge configuration from API with
 * settings from `load` function & default settings.
 *
 * Also updates window.tomis.chat variables when appropriate
 */
export async function initialize(
  id: string,
  settings: Partial<ChatWidgetConfiguration>,
): Promise<{
  /** ChatBot configuration that is necessary before creating React App */
  config?: ChatWidgetConfiguration;
  success: boolean;
  errorMessage?: string;
}> {
  window.tomis.chat.id = id;
  const api = await tomisFetch<ChatWidgetConfiguration>({
    endpoint: "/v2/webClient/initialize",
    method: "POST",
  });

  if (api.errorMessage || api.error || !api.body) {
    return {
      success: false,
      errorMessage: api.errorMessage || "Unknown error loading TOMIS ChatBot",
    };
  }

  const pageSettings = sanitizeSettings(settings);
  const firebaseSettings = sanitizeSettings(api.body);

  if (!firebaseSettings.projectId) {
    return {
      success: false,
      errorMessage:
        "TOMIS ChatBot is not configured correctly. Missing Project ID",
    };
  }

  /** Merge configuration from endpoint
   * with configuration provided to `load` function */
  const initConfig: ChatWidgetConfiguration = {
    ...DEFAULT_CHAT_CONFIGURATION,
    ...firebaseSettings,
    ...pageSettings,
    // Don't allow user to override the following settings
    disabled: firebaseSettings.disabled,
    projectId: firebaseSettings.projectId,
    watermark: firebaseSettings.watermark || true,
    spacing: {
      ...DEFAULT_CHAT_CONFIGURATION.spacing,
      ...firebaseSettings.spacing,
      ...pageSettings.spacing,
    },
    spacingMobile: {
      ...DEFAULT_CHAT_CONFIGURATION.spacingMobile,
      ...firebaseSettings.spacingMobile,
      ...pageSettings.spacingMobile,
    },
  };

  window.tomis.chat.config = initConfig;

  return {
    success: true,
    config: initConfig,
  };
}

/** Default settings before customer makes changes to configuration.
 *
 * Settings excluded from this are not absolutely necessary for the chat widget to work,
 * except for `projectId`.
 */
export const DEFAULT_CHAT_CONFIGURATION: Pick<
  ChatWidgetConfiguration,
  | "name"
  | "image"
  | "color"
  | "description"
  | "position"
  | "spacing"
  | "spacingMobile"
  | "watermark"
> = {
  name: "TOMIS Assistant",
  image:
    "https://firebasestorage.googleapis.com/v0/b/tomis-bot.appspot.com/o/bot_icons%2Ftomis.png?alt=media&token=8abd2dc8-878d-42f8-b160-a5425f0204b5",
  color: "#309F87",
  description: "Welcome to my assistant",
  position: "right",
  spacing: {
    bottom: 40,
    side: 40,
  },
  spacingMobile: {
    bottom: 40,
    side: 40,
  },
  watermark: true,
};

/** Ensure that Chat Widget Configuration only includes valid fields and types */
export function sanitizeSettings(
  settings: unknown,
): Partial<ChatWidgetConfiguration> {
  const ref = isObject(settings) ? (settings as Record<string, unknown>) : {};
  const {
    name,
    watermark,
    description,
    image,
    launcher,
    spacing,
    color,
    position,
    disabled,
    hide,
    hideWelcomeMessagesPreview,
    projectId,
    size,
    spacingMobile,
    welcomeMessages,
    welcomeMessagesDelay,
    versionId,
    googleAnalyticsIds,
  } = ref as Partial<ChatWidgetConfiguration>;

  /** Can be set via the code widget, so that the "development" version of the project can
   * be used beyond localhost or the TOMIS App.
   *
   * From [Voiceflow documentation](https://developer.voiceflow.com/reference/project)
   *
   * For the versionID header, you should pass in the value:
   * - 'development' for testing and development purposes
   * - 'production' for live apps and production deployments */
  const versionID =
    versionId === "development" || versionId === "production"
      ? versionId
      : IS_TEST_CONVERSATION
        ? "development"
        : "production";

  return {
    ...(typeof name === "string" && { name }),
    ...(typeof color === "string" && { color }),
    ...(typeof image === "string" && { image }),
    ...(typeof launcher === "string" && { launcher }),
    ...(typeof watermark === "boolean" && { watermark }),
    ...(typeof description === "string" && { description }),
    ...(typeof position === "string" && { position }),
    ...(typeof disabled === "boolean" && { disabled }),
    ...(typeof hide === "boolean" && { hide }),
    ...(typeof hideWelcomeMessagesPreview === "boolean" && {
      hideWelcomeMessagesPreview,
    }),
    ...(typeof size === "number" && { size }),
    ...(typeof projectId === "string" && { projectId }),
    ...(typeof welcomeMessagesDelay === "number" && { welcomeMessagesDelay }),
    ...(Array.isArray(welcomeMessages) && { welcomeMessages }),
    ...(Array.isArray(googleAnalyticsIds) && { googleAnalyticsIds }),
    ...(isObject(spacing) && {
      spacing: {
        side: (spacing as Spacing).side,
        bottom: (spacing as Spacing).bottom,
      },
    }),
    ...(isObject(spacingMobile) && {
      spacingMobile: {
        side: (spacingMobile as Spacing).side,
        bottom: (spacingMobile as Spacing).bottom,
      },
    }),
    versionId: versionID,
  };
}
