import { styled } from "styled-components";
import { Button as _Button } from "@tomis-tech/chat-ui";

/** Styled `<input>` */
export const Input = styled.input<{ $valid: boolean }>(({ theme, $valid }) => ({
  padding: `${theme.space[1]} ${theme.space[3]}`,
  borderRadius: theme.radii[1],
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: $valid ? theme.colors.grayDark : theme.colors.warn,
  fontSize: "16px",
  color: $valid ? theme.colors.grayDark : theme.colors.warn,
  background: "none",
  boxSizing: "border-box",
  margin: "2px",

  "&:hover, &:focus": {
    borderWidth: "3px",
    cursor: "text",
    margin: "0px",
  },

  "&:focus-visible": {
    outline: "none",
  },
}));

/** Red text */
export const ErrorMessage = styled.div<{ $absolute?: boolean }>(
  ({ theme, $absolute }) => ({
    color: theme.colors.warn,
    marginBottom: theme.space[1],
    position: $absolute ? "absolute" : "relative",
  }),
);

/** Grid for inputs, error messages, labels, etc. */
export const GridContainer = styled.div(() => {
  return {
    display: "grid",
    gridTemplateColumns: "fit-content(100%) auto",
    gridTemplateRows: "auto auto auto auto",
    alignItems: "baseline",
  };
});

/** Grid item */
export const GridItem = styled.div<{ $p?: string }>(({ $p }) => ({
  fontWeight: 400,
  padding: $p,
  // Any other styles you want for the grid items
}));
