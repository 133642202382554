import { ChatPersistence, SessionOptions } from "@/common";
import { broadcast, BroadcastType } from "./broadcast";
import { createUniqueId } from "./helpers";

const TOMIS_SESSION_KEY = "tomis-session";
const TOMIS_TRANSCRIPT_KEY = "tomis-transcript";

const getTranscriptKey = (projectID: string) =>
  `${TOMIS_TRANSCRIPT_KEY}-${projectID}`;

const getSessionKey = (projectID: string) =>
  `${TOMIS_SESSION_KEY}-${projectID}`;

const getStorageSession = (
  storage: Storage,
  projectID: string,
): SessionOptions | null => {
  try {
    return JSON.parse(storage.getItem(getSessionKey(projectID))!);
  } catch {
    return null;
  }
};

/** Retrieve transcript information from session or local storage */
export const getStorageTranscript = (
  storage: Storage,
  projectID: string,
): any => {
  try {
    return JSON.parse(storage.getItem(getTranscriptKey(projectID))!);
  } catch {
    return {};
  }
};

const setStorageSession = (
  storage: Storage,
  projectID: string,
  options: SessionOptions,
) => {
  storage.setItem(getSessionKey(projectID), JSON.stringify(options));

  return options;
};

const setStorageTranscript = (
  storage: Storage,
  projectID: string,
  transcript: any,
) => storage.setItem(getTranscriptKey(projectID), JSON.stringify(transcript));

//  we can't use function default param `userID = createId()`, cause it'll break sessions for anonymous users (when the userID is not provided by app)
const resolveSession = (
  storage: Storage,
  projectID: string,
  userID?: string,
) => {
  const session = getStorageSession(storage, projectID);

  if (!session || (userID && session.userID !== userID)) {
    return setStorageSession(storage, projectID, {
      userID: userID || createUniqueId(),
    });
  }

  return session;
};

/** Get Chat Session data from window storage.
 *  We can't use function default param `userID = createId()`,
 * cause it'll break sessions for anonymous users (when the userID is not provided by app) */
export const getSession = (
  persistence: ChatPersistence,
  projectID: string,
  userID?: string,
): SessionOptions => {
  switch (persistence) {
    case ChatPersistence.MEMORY:
      return { userID: userID || createUniqueId() };
    case ChatPersistence.LOCAL_STORAGE:
      return resolveSession(localStorage, projectID, userID);
    case ChatPersistence.SESSION_STORAGE:
    default:
      return resolveSession(sessionStorage, projectID, userID);
  }
};

/** Save Chat Session data to window storage, including turns */
export const saveSession = (
  persistence: ChatPersistence,
  projectID: string,
  session: SessionOptions,
): void => {
  broadcast({ type: BroadcastType.SAVE_SESSION, payload: session });

  if (persistence === ChatPersistence.LOCAL_STORAGE) {
    setStorageSession(localStorage, projectID, session);
  } else if (persistence === ChatPersistence.SESSION_STORAGE) {
    setStorageSession(sessionStorage, projectID, session);
  }

  if (persistence !== ChatPersistence.LOCAL_STORAGE) {
    localStorage.removeItem(getSessionKey(projectID));
  }
  if (persistence !== ChatPersistence.SESSION_STORAGE) {
    sessionStorage.removeItem(getSessionKey(projectID));
  }
};

/** Save transcript details to storage (local or session) */
export const saveTranscriptToStorage = (
  persistence: ChatPersistence,
  projectID: string,
  transcript: any,
): void => {
  broadcast({ type: BroadcastType.SAVE_TRANSCRIPT, payload: transcript });

  if (persistence === ChatPersistence.LOCAL_STORAGE) {
    setStorageTranscript(localStorage, projectID, transcript);
  } else if (persistence === ChatPersistence.SESSION_STORAGE) {
    setStorageTranscript(sessionStorage, projectID, transcript);
  }

  if (persistence !== ChatPersistence.LOCAL_STORAGE) {
    localStorage.removeItem(getTranscriptKey(projectID));
  }
  if (persistence !== ChatPersistence.SESSION_STORAGE) {
    sessionStorage.removeItem(getTranscriptKey(projectID));
  }
};
