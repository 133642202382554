import appStyles from "./css/app-styles.css?raw";

/** Inject additional CSS styles inside shadow root.
 *
 * By default, vite will inject styles into the main document,
 * so we need to manually inject the styles into the shadow root like this.
 */
export const injectStylesShadowRoot = (shadowRoot: ShadowRoot) => {
  const style = document.createElement("style");
  style.id = "app-styles";
  style.innerHTML = appStyles;
  shadowRoot.appendChild(style);
};
