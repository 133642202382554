import { useState } from "react";
import {
  ProductListing,
  CheckAvailabilityDate,
  CheckAvailabilityDuration,
} from "@tomis-tech/types";

import {
  addDays,
  dateStringToLocaleString,
  getLocaleDateString,
} from "@/utils";
import { trackEvent } from "@/tracking";

const TOO_FAR_IN_FUTURE = "Date range must be within 30 days";

export interface UseDatesProps extends CheckAvailabilityDate {
  /** Products are necessary to determine if we should show
   * the end date input, or only the start date input on mount.*/
  products: ProductListing[];
}

type DateState = {
  /** Date is formatted like `YYYY-MM-DD`, which is a value needed for `<input type="date" />` elements. */
  value: string;
  /** If there is an error message, the date is invalid. */
  errorMessage?: string;
};

/** Values returned from `useDates` hook */
export interface UseDatesReturn {
  /** Handle `onChange` event for `<input type="date" />` */
  handleEndChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Handle `onChange` event for `<input type="date" />` */
  handleStartChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** If `true`, render end date input. If `false` show only start date input.  */
  isRange: boolean;
  /** Toggle showing end and start dates, or only start date */
  toggleIsRange: () => any;
  /** Date is formatted like `YYYY-MM-DD`, which is a value needed for `<input type="date" />` elements.
   *
   * If there is an error message, the date is invalid. */
  start: DateState;
  /** Date is formatted like `YYYY-MM-DD`, which is a value needed for `<input type="date" />` elements.
   *
   * If there is an error message, the date is invalid. */
  end: DateState;
  /** Formatted like `YYYY-MM-DD`. Will be today's date, or minimum allowed date, whichever is earlier */
  startMin: string;
}

/** Show availability selector in a selector that slides from the bottom  */
export const useDates = ({
  min,
  max,
  products,
}: UseDatesProps): UseDatesReturn => {
  /** Ideally, duration type for every product should be the same!
   *
   * This is used to determine if both start and end date should be shown.  */
  const duration: CheckAvailabilityDuration = products.some(
    (p) => p.duration === "variable",
  )
    ? "variable"
    : "fixed";

  const todayDate = getLocaleDateString();

  /** Today, or a future start date when operator begins tours */
  const startDateMinimum =
    !min || new Date(todayDate) > new Date(min) ? todayDate : min;

  const [startDate, setStartDate] = useState<DateState>({
    value: startDateMinimum,
  });
  const [endDate, setEndDate] = useState<DateState>({
    value: startDateMinimum,
  });
  const [showDateRange, setShowDateRange] = useState(duration === "variable");

  function handleStartDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newStartDate = e.target.value;
    const isBeforeMinimum = new Date(newStartDate) < new Date(startDateMinimum);
    const isAfterMaximum = max && new Date(newStartDate) > new Date(max);

    if (newStartDate === "") {
      return setStartDate({ value: newStartDate, errorMessage: "Required" });
    }

    if (isBeforeMinimum) {
      return setStartDate({
        value: newStartDate,
        errorMessage: `No availability before ${dateStringToLocaleString(
          startDateMinimum,
        )}`,
      });
    } else if (isAfterMaximum) {
      return setStartDate({
        value: newStartDate,
        errorMessage: `No availability after ${dateStringToLocaleString(max)}`,
      });
    } else {
      setStartDate({ value: newStartDate });

      /** Don't let user search more than 30 days at a time */
      const isTooFarInFuture =
        showDateRange &&
        new Date(endDate.value) > addDays(new Date(newStartDate), 30);

      if (isTooFarInFuture) {
        setEndDate({
          value: endDate.value,
          errorMessage: TOO_FAR_IN_FUTURE,
        });
      } else if (new Date(newStartDate) > new Date(endDate.value)) {
        setEndDate({ value: newStartDate });
      } else if (endDate.errorMessage === TOO_FAR_IN_FUTURE) {
        setEndDate({ value: endDate.value }); // clear error
      }
    }
  }

  function handleEndDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newEndDate = e.target.value;
    const isBeforeMinimum = new Date(newEndDate) < new Date(startDate.value);
    const isAfterMaximum = max && new Date(newEndDate) > new Date(max);

    if (newEndDate === "") {
      return setEndDate({ value: newEndDate, errorMessage: "Required" });
    }

    if (isBeforeMinimum) {
      return setEndDate({
        value: newEndDate,
        errorMessage: "Must be after start date",
      });
    }

    /** Don't let user search more than 30 days at a time */
    const isTooFarInFuture =
      new Date(newEndDate) > addDays(new Date(startDate.value), 30);

    if (isTooFarInFuture) {
      return setEndDate({
        value: newEndDate,
        errorMessage: TOO_FAR_IN_FUTURE,
      });
    }

    if (isAfterMaximum) {
      return setEndDate({
        value: newEndDate,
        errorMessage: `No availability after ${dateStringToLocaleString(max)}`,
      });
    }

    // validated end date
    setEndDate({ value: newEndDate });
  }

  function toggleIsRange() {
    trackEvent("check_availability_select_date_range", {
      date_range: !showDateRange,
    });
    setShowDateRange(!showDateRange);
  }

  return {
    handleEndChange: handleEndDateChange,
    handleStartChange: handleStartDateChange,
    isRange: showDateRange,
    toggleIsRange,
    end: endDate,
    start: startDate,
    startMin: startDateMinimum,
  };
};
