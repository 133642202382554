/** Standard events to track inside widget.
 *
 * Mirrors GA4Event from Dialogflow chatbot
 */
export enum TomisEvent {
  /** Chat widget loads on the page */
  Loaded = "loaded",
  /** Technical error occurred when trying to load the ChatBot:
   * - Error initializing chatbot (api) or other technical error
   * when trying to load chatbot iframe */
  LoadFailed = "load_failed",
  /** Several reasons that chatbot shouldn't load on the page:
   * - Bot isn't allowed to load on parent window
   * - Crawler bots are detected and blocked (e.g. Googlebot)
   * - Chatbot is disabled
   * - Chatbot not found */
  LoadPrevented = "load_prevented",
  /** Chatbot responds to customer question */
  MessageReceivedAutomated = "message_received_automated",
  /** Live Chat support sends a message */
  MessageReceivedSupport = "message_received_support",
  /** Customer sends a message to chatbot */
  MessageSentCustomer = "message_sent_customer",
  /** Customer requests live chat with support agent */
  WebchatRequested = "webchat_requested",
  /** Live chat request is accepted by support agent */
  WebchatAccepted = "webchat_accepted",
  /** Live chat request is missed by support agent */
  WebchatMissed = "webchat_missed",
  /** Customer completes form to request SMS Chat */
  LeadCapturedSMS = "lead_captured_sms",
  /** Customer completes form to request Live Chat */
  LeadCapturedWebchat = "lead_captured_webchat",
  /** Chatbot window is opened */
  WindowOpened = "window_opened",
  /** Chatbot window is closed */
  WindowClosed = "window_closed",
  /** Customer clicked phone number link */
  ClickedPhone = "clicked_phone",
  /** Customer clicked email address */
  ClickedEmail = "clicked_email",
  /** Customer clicked url */
  ClickedUrl = "clicked_url",
  /** Customer clicked on Powered By TOMIS logo */
  ClickedPoweredByTomis = "clicked_powered_by_tomis",
  /** Customer clicked "Return to ChatBot conversation" */
  ClickedReturnToChatbot = "clicked_return_to_chatbot",
  /** Customer clicked on "Request SMS Chat" link */
  ClickedRequestSms = "clicked_return_to_request_sms",
  /** Customer clicked on a quick reply */
  ClickedQuickReply = "clicked_quick_reply",
  /** Customer checked availability (all parameters are valid) */
  CheckAvailabilitySearch = "check_availability_search",
  /** Request to check availability was successful, but no availability was found */
  CheckAvailabilityNoAvailabilityFound = "check_availability_no_availability_found",
  /** API error (or other error) when checking availability */
  CheckAvailabilityError = "check_availability_error",
  /** Successful API request + availability found for at least one trip */
  CheckAvailabilityAvailabilityFound = "check_availability_availability_found",
  /** Customer modifies the quantity */
  CheckAvailabilitySelectQuantity = "check_availability_select_quantity",
  /** Customer selects a date */
  CheckAvailabilitySelectDate = "check_availability_select_date",
  /** Customer selects an individual product */
  CheckAvailabilitySelectProduct = "check_availability_select_product",
  /** Customer selects a date range, instead of an individual day */
  CheckAvailabilitySelectDateRange = "check_availability_select_date_range",
  CheckAvailabilityClickMoreTimes = "check_availability_click_more_times",
  /** Customer clicked a button representing the departure time  */
  CheckAvailabilityLinkClick = "check_availability_link_click",
  /** Error boundary was reached */
  Crashed = "crashed",
  /** Customer clicked button to "Restart Session" */
  SessionRestarted = "session_restarted",
  /** Chatbot user marked system message as "positive" with a thumbs up */
  FeedbackPositive = "feedback_positive",
  /** Chatbot user marked system message as "negative" with a thumbs down */
  FeedbackNegative = "feedback_negative",
  /** Chatbot user left a message for chatbot maintainers to fix */
  FeedbackMessage = "feedback_message",
  /** Chatbot maintainer fixed the feedback left by users */
  FeedbackResolved = "feedback_resolved",
}
