import React, { useCallback, useContext, useEffect, useState } from "react";
import { Trace } from "@voiceflow/base-types";
import { ChatWidgetConfiguration } from "@tomis-tech/types";
import { FeedbackProps, Launcher, Proactive } from "@tomis-tech/chat-ui";

import { useIsMobile } from "@/hooks/useIsMobile";
import { TomisEvent, trackEvent } from "@/tracking";
import { ChatUser, SessionStatus } from "@/common";
import { RuntimeStateAPIContext, RuntimeStateContext } from "@/contexts";
import { ChatContainer, Container, LauncherContainer } from "./styled";
import { ChatOpen } from "../ChatOpen";

export interface ChatWidgetProps {
  /** Callback function to run after mount and `window.tomis.chat` API is initialized */
  onReady: () => any;
  /** Chat widget configuration stored in Firebase */
  config: ChatWidgetConfiguration;
  /** If `true` load chat widget inside a <div> on the page */
  embed: boolean;
  /** Feedback mode for chat widget */
  feedback: FeedbackProps["mode"];
  user: ChatUser;
}

/** Entire Chat Widget, include closed and open views */
export const ChatWidget: React.FC<React.PropsWithChildren<ChatWidgetProps>> = ({
  onReady,
  config,
  embed = false,
  feedback = "disabled",
  user = {},
}) => {
  const { open, close, interact, launch } = useContext(RuntimeStateAPIContext);
  const { isOpen, session, transcript } = useContext(RuntimeStateContext);
  const [isHidden, setHidden] = useState(Boolean(config.hide));
  const [proactiveMessages, setProactiveMessages] = useState<Trace.AnyTrace[]>(
    [],
  );
  const isMobileDevice = useIsMobile();

  const openWidget = useCallback(async () => {
    await open();
    trackEvent(TomisEvent.WindowOpened);
  }, [open]);

  const closeWidget = useCallback(() => {
    close();
    trackEvent(TomisEvent.WindowClosed);
  }, [close]);

  // automatically start chat session for embedded mode
  useEffect(() => {
    if (
      session.status === SessionStatus.IDLE &&
      session.turns.length === 0 &&
      embed
    ) {
      launch();
    }
  }, [launch, session.status, session.turns.length, embed]);

  /** Setup window.tomis.chat API */
  useEffect(() => {
    // interact is same for embed and non-embed
    window.tomis.chat.interact = interact;

    if (embed) {
      window.tomis.chat.open = () =>
        console.warn("[TOMIS Chatbot] Cannot 'open' chat widget in embed mode");
      window.tomis.chat.close = () =>
        console.warn(
          "[TOMIS Chatbot] Cannot 'close' chat widget in embed mode",
        );
      window.tomis.chat.hide = () =>
        console.warn("[TOMIS Chatbot] Cannot 'hide' chat widget in embed mode");
      window.tomis.chat.show = () =>
        console.warn("[TOMIS Chatbot] Cannot 'show' chat widget in embed mode");
      window.tomis.chat.proactive = {
        clear: () => setProactiveMessages([]),
        push: () =>
          console.warn(
            "[TOMIS Chatbot] Cannot use 'proactive' messages in embed mode",
          ),
      };
    } else {
      // regular chat widget
      window.tomis.chat.open = openWidget;
      window.tomis.chat.close = closeWidget;
      window.tomis.chat.hide = () => setHidden(true);
      window.tomis.chat.show = () => setHidden(false);
      window.tomis.chat.proactive = {
        clear: () => setProactiveMessages([]),
        push: (...messages: Trace.AnyTrace[]) =>
          setProactiveMessages((prev) => [...prev, ...messages]),
      };
    }

    // This will resolve `createWidget` promise
    onReady();
  }, [openWidget, closeWidget, interact, onReady, embed]);

  async function handleOpenAndLaunch() {
    setProactiveMessages([]);
    openWidget(); // will also "launch" widget and start chat session
  }

  const side = config.position;
  const launcherStyle = {
    bottom: isMobileDevice
      ? config.spacingMobile.bottom
      : config.spacing.bottom,
    [side]: isMobileDevice ? config.spacingMobile.side : config.spacing.side,
  };
  const chatWindowStyle = isMobileDevice
    ? undefined
    : {
        bottom: config.spacing.bottom,
        [side]: config.spacing.side,
      };

  // keep window.tomis.chat in sync
  window.tomis.chat.session = session;
  window.tomis.chat.transcript = transcript;
  window.tomis.chat.embed = embed;
  window.tomis.chat.feedback = feedback;
  window.tomis.chat.user = user;

  if (embed) {
    return (
      <ChatOpen
        description={config.description}
        image={config.image}
        name={config.name}
        watermark={config.watermark}
        embed={true}
        feedback={feedback}
        user={user}
      />
    );
  }

  return (
    <Container id="tomis-chat-widget" $withChat={isOpen} $isHidden={isHidden}>
      <LauncherContainer id="tomis-chat-closed" style={launcherStyle}>
        <Proactive
          side={side}
          messages={proactiveMessages}
          onMessageClick={handleOpenAndLaunch}
        />
        <Launcher
          onClick={handleOpenAndLaunch}
          image={config.launcher}
          size={config.size}
        />
      </LauncherContainer>
      <ChatContainer id="tomis-chat-open" style={chatWindowStyle}>
        <ChatOpen
          description={config.description}
          image={config.image}
          name={config.name}
          watermark={config.watermark}
          embed={false}
          feedback={feedback}
          user={user}
        />
      </ChatContainer>
    </Container>
  );
};
