import { StringifiedEnum } from "@/common/types";
import { setupGoogleAnalytics4, trackGA4Event } from "./googleAnalytics";
import { TomisEvent } from "./types";
import pkg from "../../package.json";

export { TomisEvent } from "./types";
export { setSentryTags, initSentry } from "./sentry";

/** Initialize tracking for the chat widget.
 *
 * This function will setup Google Analytics 4 for tracking events.
 */
export function initializeTracking() {
  setupGoogleAnalytics4();
}

/** Track chat widget event across all tracking platforms
 * - Google Analytics 4
 *
 * **Will ONLY log to console in "development" mode**, which is controlled
 * by the "version" setting in the chat widget code snippet. The
 * version will determine which Voiceflow runtime to use, prod or dev.
 * By default,ChatBots on app.tomis.tech, dev.tomis.tech, and localhost will
 * be in "development" mode, unless specified otherwise.
 */
export function trackEvent(
  eventName: StringifiedEnum<TomisEvent>,
  eventParams: Record<string, any> = {},
) {
  /** Ensure we aren't directly modifying payload */
  const params = { ...eventParams };

  params.site = window.tomis?.chat?.id;
  params.status = window.tomis?.chat?.session?.status;
  params.release = pkg.version;
  params.embed = window.tomis?.chat?.embed;
  params.feedback = window.tomis?.chat?.feedback;
  // Don't use `user_id` as it's a reserved parameter in GA4
  params.user_chat_id = window.tomis?.chat?.session?.userID;
  // Get information from users browser
  params.browser_url = window.location.href;
  params.browser_info = window.navigator.userAgent;
  params.browser_screen_width = window.screen.width;
  params.browser_screen_height = window.screen.height;
  params.browser_language = window.navigator.language;

  if (window.tomis.chat.config.versionId === "development") {
    console.groupCollapsed(`Event: ${eventName}`);
    for (const key in params) {
      console.log(`${key}: ${params[key]}`);
    }
    console.groupEnd();
    return;
  } else {
    trackGA4Event(eventName, params);
  }
}
